.post-icon-group {
  height: 30px;
}
.no-posts__text {
  text-align: center;
  padding: 40px 0;
}
.feedback-block {
  &__tags {
    margin-top: 20px;
  }
  video {
    width: 100%;
    max-height: 550px;
  }
}
.feedback-change-text {
  width: 100%;
  height: 150px;
  resize: none;
  margin: 20px 0;
  border-color: var(--campagin-primary-text-color);
  padding: 10px;
}
