:root {

  --black-lite: #b4b4b4;
  --main-iol-dark: #50894c;
  --gray-light: #f5f7ff;
  --gray-darck: #F1F3FF;
  --red-hover: #DF1C28;
  --yellow: #E8AF1F;
  --yellow-hover: #E0A204;
  --bright-green: #97e241;


  --campagin-primary-text-color: var(--main-iol-light);


  --paypal-button-color: #FFC339;
  --paypal-button-hover-color: #ffba1a;


  .campaign-secondary-btn {
    @include button(var(--blue), var(--blue-hover), 5px);
    &.active {
      border-color: var(--main-iol-hover);
      background-color: var(--main-iol-hover);
      color: var(--white);
      @media (hover: hover) {
        &:hover {
          border-color: var(--main-iol);
          background-color: var(--main-iol);
        }
      }
    }
  }

  .primary-btn-radius {
    @include button(var(--blue), var(--main-iol-hover), 30px);
  }
  .secondary-btn-radius {
    @include button(var(--green), var(--green-hover), 30px);
  }

  .campagin-primary-btn-text {
    @include button-text(var(--main-iol), var(--main-iol));
  }

  .primary-btn-circle {
    @include button-text(var(--green), var(--green-hover));
  }
  .secondary-btn-circle {
    @include button-text(var(--yellow), var(--yellow-hover));
  }
  .tertiary-btn-circle {
    @include button-text(var(--red), var(--red-hover));
  }
  .information {
    color: var(--green);
    @media (hover: hover) {
      &:hover {
        color: var(--green-hover);
      }
    }
  }


  // Form
  .switch {
    color: var(--secondary-text-color);
    &__input {
      &:checked {
        ~ .switch__bg {
          background-color: var(--green);
        }
      }
    }
    &__bg {
      background-color: var(--red);
    }
  }


  // My campaign
  .campaign-box__hover, .campaign-box__on_review {
    background: rgba(0, 205, 255, 0.57);;
    backdrop-filter: blur(5px);
    color: var(--gray);
  }


  // My campaign overview feedback
  .feedback-block {
    background-color: var(--gray-light);
    &__middle {
      background-color: var(--white);
    }
  }
  .feedback-info {
    background-color: var(--gray-light);
  }
  .feedback-info, .modal-post-info {
    &__avatar {
      border: 1px solid #DADADA;
    }
    &__title {
      color: var(--green);
    }
  }
  .feedback-price, .post-info-price-all {
    background-color: var(--blue-light);
  }


  // My campaign overview
  .overview-header {
    &__open-until {
      color: var(--gray);
    }
    &__colum {
      color: var(--secondary-text-color);
      &.right {
        color: var(--purple);
      }
    }
    &__line {
      &-one {
        background-color: var(--green);
      }
      &-two {
        background-color: var(--bright-green);
      }
    }
    &__text {
      &.small {
        color: var(--gray);
      }
    }
  }
  .overview-filter {
    &__line {
      background-color: var(--gray);
    }
    .select-box {
      color: var(--blue);
    }
  }
  .select {
    &:after {
      border: solid var(--blue);
      border-width: 0 2px 2px 0;
    }
    select {
      background-color: var(--white);
    }
  }
  .filter-arrow {
    color: var(--blue);
    &:active {
      color: var(--main-iol);
    }
    @media (hover: hover) {
      &:hover {
        color: var(--blue-hover);
      }
    }
  }
  .filter-soc-net {
    color: var(--blue);
    &__item {
      &:after {
        border: 3px solid var(--green);
      }
    }
  }
  .post-block {
    background-color: var(--gray-light);
    &__price {
      color: var(--main-iol);
    }
    &__text {
      color: var(--black-lite);
    }
  }
  .post-card {
    &__name {
      color: var(--green);
    }
    &__date {
      color: var(--black-lite);
    }
  }


  // New campaign
  .new-campaign {
    h2 {
      color: var(--primary-text-color);
    }
  }
  .steps {
    color: var(--secondary-text-color);
    &__title {
      color: var(--white-hover);
      &.active {
        color: currentColor;
      }
    }
    &__line {
      background-color: currentColor;
    }
  }
  .new-campaign-form {
    .form__input, .form__textarea {
      color: currentColor;
      &:focus {
        border-color: var(--main-iol-light);
      }
    }
    .form__input {
      &::-webkit-input-placeholder { color: var(--main-iol-dark); }
      &:-ms-input-placeholder { color: var(--main-iol-dark); }
      &::-ms-input-placeholder { color: var(--main-iol-dark); }
      &::placeholder { color: var(--main-iol-dark); }
    }
  }
  .tile {
    background-color: var(--gray);
  }
  .upload {
    &__add {
      background-color: #F9FAFF;
      @media (hover: hover) {
        &:hover {
          background-color: var(--blue);
          .upload__text, .upload__icons {
            color: var(--white);
          }
        }
      }
    }
    &__icons {
      color: var(--gray);
    }
    &__text {
      color: var(--blue-white);
    }
  }
  .interests {
    background-color: #F9FAFF;
    &__text {
      color: var(--main-iol-light);
    }
    &__svg {
      fill: var(--blue-light);
    }
    @media (hover: hover) {
      &:hover {
        background-color: var(--blue-light);
        .interests__svg {
          fill: var(--white);
        }
        .interests__text {
          color: var(--white);
        }
      }
    }
    &.active {
      background-color: var(--main-iol-light);
      .interests__svg {
        fill: var(--white);
      }
      .interests__text {
        color: var(--white);
      }
    }
  }
  .new-campaign-review {
    &__title {
      color: var(--main-iol-light);
    }
    &__logo-title {
      color: var(--main-iol);
    }
  }
  .tags {
    &__text {
      background-color: var(--gray);
    }
  }


  // Modals
  .contact-brand {
    &__price {
      background-color: var(--blue-light);
    }
    &__text-info {
      color: var(--blue);
    }
  }
  .contact-brand-tile {
    &__colum {
      background-color: var(--gray-darck);
    }
  }
  .post-info {
    &__submitted {
      color: var(--blue);
      border-top: 1px solid var(--gray);
    }
  }
  .post-info-price {
    &:nth-child(odd) {
      .post-info-price__colum {
        background-color: var(--gray-light);
      }
    }
    &:nth-child(even) {
      .post-info-price__colum {
        background-color: var(--gray-darck);
      }
    }
  }
}
