@media screen and (min-width: 768px) and (max-width: 1400px) {
  .feedback-info {
    padding: 12px 12px;
    &__avatar {
      width: 56px;
      height: 56px;
      min-width: 56px;
    }
    &__title {
      font-size: 20px;
      line-height: 20px;
    }
    &__platform {
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-left: 6px;
    }
  }
  .feedback-price {
    padding: 10px 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .profile-block__line {
    width: 100px;
  }
}

@media screen and (min-width: 1281px) {
  .menu-btn, .header__block .soc-seti {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .overview-filter {
    .wrapper {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }
  .header {
    &__block {
      position: fixed;
      top: 0;
      right: 0;
      flex-direction: column;
      background-color: #fff;
      height: 100vh;
      width: 300px;
      padding: 75px 30px 40px;
      align-items: flex-end;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 10;
      transition: .35s ease;
      transform: translateX(100%);
      &.active {
        transform: translateX(0);
      }
    }
    &__group {
      margin: auto 0;
      flex-direction: column;
      align-items: flex-end;
    }
    .btn-text {
      min-height: 26px;
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 16px;
      }
    }
    .btn {
      min-height: 50px;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .soc-seti {
      margin-top: 20px;
    }
  }
  .menu {
    flex-direction: column;
    align-items: end;
    margin-top: auto;
    width: 100%;
    padding: 0;
    &__item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 12px;
    }
    &__link {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .interests {
    &__svg {
      width: 5vw;
      min-width: 5vw;
      max-width: 70px;
    }
    &__text {
      font-size: 15px;
    }
  }
  .overview-main .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .tile__box {
    &.small {
      height: 11.2vw;
      max-height: 140px;
    }
    &.big {
      height: 28vw;
      max-height: 350px;
    }
  }
  .overview-header {
    &__row:nth-child(2) {
      padding: 35px 0;
    }
    &__left {
      margin-right: 20px;
      min-width: 330px;
    }
    &__colum {
      &.left {
        width: 210px;
      }
      &.right {
        width: 210px;
      }
    }
    &__text.big {
      font-size: 20px;
    }
    &__number {
      &.big {
        font-size: 20px;
      }
      &.large {
        font-size: 18px;
      }
    }
  }
  .profile-block__main-title {
    font-size: 30px;
    padding-right: 14px;
  }
  .profile-block__line {
    margin: 0 20px 4px 0;
  }
  .footer__colum:nth-child(3) {
    width: 260px;
    min-width: 260px;
  }
  .campaign__middle {
    grid-gap: 10px 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1180px) {
  .profile-block__middle {
    padding: 0 50px 0 0;
  }
  .profile-block--w-54 .profile-block__middle, .profile-block--w-46 .profile-block__middle {
    padding: 0 40px 0 0;
  }
  .profile-block .form__title {
    font-size: 15px;
  }
  .campaign-box__text {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .new-campaign-submit {
    .mt-50 {
      margin-top: 40px;
    }
    .mt-30 {
      margin-top: 5px;
    }
    &__wrapper-colum {
      max-width: 621px;
      margin-left: unset;
    }
  }
  .new-campaign-review, .new-campaign-submit {
    &__colum {
      &:nth-child(1) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
    &__wrapper {
      flex-direction: column;
    }
  }
  .overview-header {
    h2 {
      font-size: 18px
    }
    &__img {
      height: 140px;
    }
    &__row {
      &:nth-child(2) {
        padding: 20px 0;
      }
    }
    .btn {
      max-width: 180px;
    }
    &__gorup:not(:last-child) {
      margin-bottom: 8px;
    }
    &__line {
      &-one, &-two {
        height: 2px;
      }
    }
  }
  .new-campaign-form {
    &__wrapper {
      flex-direction: column;
    }
    &__colum {
      &:first-child {
        margin: 0 0 20px;
      }
    }
  }
  .profile {
    .switch {
      margin-top: 16px;
      margin-left: 20px;
    }
    &__header {
      .btn-text {
        display: none;
      }
    }
  }
  .profile-block-bar {
    padding: 34px 0;
    .profile-block {
      margin-bottom: 0;
    }
  }
  .profile-block {
    margin-bottom: 50px;
    position: relative;
    &--left, &--right {
      padding-bottom: 0;
      .profile-block__colum {
        max-width: 100%;
      }
    }
    &--right {
      margin-bottom: 0;
    }
    .form__title {
      font-size: 14px;
    }
    &:not(:last-child) .profile-block__middle {
      margin-bottom: 0;
    }
    &__middle {
      padding: 0;
    }
    &__top {
      margin-bottom: 30px;
      .btn-text {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-left: 20px;
      }
    }
    &__colum:first-child {
      margin-left: 0;
    }
    &__main-title {
      font-size: 24px;
      padding-right: 0;
    }
    &__line {
      width: 76px;
      margin: 0 16px 3px 0;
    }
  }
  .feedback__bg {
    height: 200px;
  }
  footer {
    padding-bottom: 30px;
  }
  .footer {
    &__colum:nth-child(2) {
      margin: 0;
    }
    &__row {
      &:nth-child(1) {
        flex-wrap: wrap;
        .footer__colum {
          &:nth-child(1) {
            width: 100%;
          }
        }
      }
      &:nth-child(2) {
        flex-direction: column;
        .footer__colum {
          &:nth-child(1) {
            order: 2;
          }
          &:nth-child(2) {
            max-width: 100%;
            order: 3;
          }
          &:nth-child(3) {
            order: 1;
          }
        }
      }
    }
    .select-box {
      position: absolute;
      top: 0;
      right: 0;
      margin: 16px 30px 0 0;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .overview-filter {
    .overview-filter__btn-group {
      order: 1;
      max-width: 400px;
    }
    .filter-soc-net {
      order: 2;
      margin-left: 16px;
    }
    .overview-filter__select-group {
      order: 3;
      margin: 20px 0 0;
      max-width: 706px;
      .select-box {
        &:first-child {
          max-width: 344px;
          margin-right: 16px;
        }
        &:nth-child(2) {
          max-width: 298px;
        }
      }
    }
  }
  .btn-circle__svg {
    width: 46px;
    height: 46px;
    min-width: 46px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .new-campaign-moodboard {
    grid-template-columns: repeat(3, 1fr);
  }
  .overview-header {
    h2 {
      margin: 12px 0;
    }
    .btn {
      margin-bottom: 10px;
    }
    &__bg {
      height: 130px;
    }
    &__left {
      margin-right: 16px;
      width: 100%;
      max-width: 256px;
    }
    &__line {
      width: 62px;
    }
  }
  .new-campaign h2 {
    font-size: 48px;
  }
  .steps__title {
    font-size: 16px;
  }
  .profile-block {
    padding-bottom: 42px;
  }
  .modal-wallet {
    .modal__cont {
      padding: 60px 30px 50px;
    }
    &__bar .modal-wallet__colum {
      &:nth-child(1) {
        width: 350px;
        padding-right: 30px;
      }
      &:nth-child(2) {
        width: calc(100% - 350px);
      }
    }
  }
  .modal-post {
    .modal__cont {
      padding: 60px 30px 50px;
    }
    .post-info {
      justify-content: center;
      &__colum:nth-child(1) {
        margin-right: 30px;
      }
    }
  }
  .feedback__cont {
    padding: 30px 0 50px;
  }
  .feedback-block__img {
    height: 40vw;
  }
  .campaign__top-line {
    width: 75px;
    margin: 0 16px 12px 0;
  }
  .campaign__middle {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 32px;
  }
  .footer {
    &__text.email {
      margin: 50px 0 16px 0;
    }
    &__row {
      &:nth-child(1) {
        margin-bottom: 50px;
        .footer__colum {
          &:nth-child(1) {
            margin-bottom: 40px;
          }
          &:nth-child(2) {
            max-width: calc(100% - 188px);
          }
          &:nth-child(3) {
            position: relative;
            min-width: 158px;
            width: 158px;
          }
        }
      }
    }
    .btn-text {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: -76px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .overview-header {
    &__text {
      &.big {
        font-size: 14px;
        line-height: 24px;
      }
      &.small {
        font-size: 10px;
        letter-spacing: 0.4px;
      }
    }
    &__line {
      margin: 8px 0 10px;
    }
    &__number {
      &.big {
        font-size: 22px;
      }
      &.large {
        font-size: 18px;
        margin: 6px 0 4px;
      }
    }
    &__colum {
      &.left, &.right {
        width: 140px;
      }
    }
    &__line-one, &__line-two {
      width: 26px;
    }
  }
  .overview-main .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .feedback__cont {
    grid-template-columns: repeat(2, 1fr);
  }
  .campaign-box__img {
    height: 24vw;
  }
}

@media screen and (min-width: 768px) {
  .overview-filter-btn-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .new-campaign-form {
    padding: 0 15px;
    &__main-title {
      font-size: 24px;
      margin-bottom: 30px;
      &:before {
        width: 74px;
        margin-right: 16px;
        margin-bottom: 2px;
      }
    }
  }
  .new-campaign-moodboard {
    grid-gap: 16px;
  }
  .overview-header {
    h2 {
      margin: 0 0 20px;
      padding-right: 0;
      text-align: center;
      line-height: 1.4;
    }
    .btn {
      margin-bottom: 20px;
    }
    &__row:nth-child(1) {
      flex-direction: column;
      padding-bottom: 162px;
    }
    &__open-until {
      margin-bottom: 0;
      line-height: 1;
    }
    &__left {
      position: absolute;
      bottom: 0;
      left: 50%;
      max-width: 266px;
      width: 100%;
      transform: translateX(-50%);
    }
    &__bg {
      height: 230px;
    }
    &__line {
      width: 42px;
    }
  }
  .overview-filter-btn-mobile {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: var(--main-bg);
    margin-top: -111px;
    padding: 95px 15px 20px;
    z-index: 1;
    .overview-filter__line {
      width: calc(100% - 30px);
    }
    .btn {
      max-width: 210px;
      width: 100%;
      height: 56px;
      padding: 0 4px;
      &:first-child {
        margin-right: 16px;
      }
      &__text {
        font-size: 14px;
      }
    }
  }
  .overview-main {
    padding-top: 0;
  }
  .overview-filter {
    position: relative;
    padding-top: 0;
    margin-top: 0;
    .wrapper {
      padding-bottom: 20px;
      padding-top: 18px;
    }
    .overview-filter__btn-group, .overview-filter__line {
      display: none;
    }
    &__select-group {
      order: 2;
      margin: 0;
      max-width: 100%;
    }
    .filter-soc-net {
      order: 1;
    }
  }
  .tile__box.big {
    height: 53vw;
    max-height: 350px;
  }
  .new-campaign h2 {
    font-size: 36px;
  }
  .steps {
    justify-content: center;
    &__bg {
      width: calc(100% - 30px);
    }
    &__title:not(:first-child), &__line:not(:first-child) {
      display: none;
    }
  }
  .campaign-box__text {
    font-size: 18px;
  }
  .profile-block {
    padding-bottom: 26px;
    .form__box {
      margin-bottom: 20px;
    }
    &__middle {
      position: relative;
      flex-direction: column;
    }
    &__colum {
      margin-left: 0;
      .btn-text {
        position: absolute;
        right: 0;
        top: 0;
        margin: -8px 0 0;
      }
    }
  }
  .profile {
    &__header {
      margin-bottom: 28px;
    }
  }
  .modal-wallet {
    &__bar {
      padding: 30px 0;
      .modal-wallet__cont {
        align-items: unset;
        flex-direction: column;
      }
      .modal-wallet__colum {
        &:nth-child(1) {
          width: 100%;
          padding-right: 0;
          margin-bottom: 34px;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
      .form__box {
        margin-bottom: 0;
      }
    }
    &__row {
      flex-direction: column-reverse;
      .modal-wallet__text:last-child {
        text-align: left;
        margin-bottom: 12px;
      }
    }
    .pay-pal-btn {
      margin: 20px auto 0;
    }
  }
  .post-info-price-all {
    margin-bottom: 12px;
  }
  .post-info {
    flex-direction: column;
    position: relative;
    align-items: center;
    padding-bottom: 88px;
    &__colum:nth-child(1) {
      max-width: 406px;
      margin-right: 0;
    }
    &__btn-group {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 206px;
    }
    &__img {
      height: 120vw;
      max-height: 450px;
    }
  }
  .campaign-review {
    .post-info__btn-group {
      bottom: 100px;
    }
    .post-info {
      padding-bottom: 200px;
    }
  }

  .feedback .wrapper {
    padding: 0 30px;
  }
  .feedback__cont {
    padding: 30px 0 40px;
  }
  .feedback-block {
    padding: 8px;
    &__middle {
      padding: 14px;
    }
    &__bottom {
      padding-top: 10px;
    }
  }
  .btn-circle__svg {
    width: 36px;
    height: 36px;
    min-width: 36px;
  }
  .btn-circle__text {
    margin-top: 4px;
  }
  .feedback-info {
    padding: 8px 10px;
    &__avatar {
      width: 44px;
      height: 44px;
      min-width: 44px;
      margin-right: 8px;
    }
    &__title {
      font-size: 18px;
      line-height: 20px;
    }
    &__subtitle {
      font-size: 12px;
      line-height: 14px;
    }
    &__platform {
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-left: 6px;
    }
  }
  .feedback-price {
    padding: 4px 10px;
    margin-bottom: 10px;
    &__text {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .feedback__bg {
    width: calc(100% - 32px);
    margin: 0 15px;
  }
  .campaign-box__title {
    padding: 8px 0;
  }
  .campaign__number {
    padding-left: 6px;
    min-width: 64px;
  }
  .campaign__middle {
    grid-gap: 10px 15px;
  }
  .campaign__bg {
    width: calc(100% - 30px);
    margin-left: 15px;
  }
  .campaign__main-title, .campaign__number {
    font-size: 24px;
    line-height: 25px;
  }
  .campaign__top-line {
    margin: 0 16px 8px 0;
  }
  .pb-160 {
    padding-bottom: 100px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .mb-100 {
    margin-bottom: 80px;
  }
  .mt-100 {
    margin-top: 80px;
  }
  .mb-60 {
    margin-bottom: 50px;
  }
  .mt-160 {
    margin-top: 80px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  h3 {
    font-size: 18px;
  }
  h2 {
    font-size: 48px;
    margin-bottom: 28px;
    word-wrap: break-word;
  }
  .title-krona {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .header {
    &:after {
      left: -15px;
      width: calc(100% + 30px);
      transform: unset;
    }
    &-left:after {
      background: var(--primary-gradient-header);
    }
    &-right:after {
      background: var(--secondary-gradient-header);
    }
  }
  .logo {
    &__text {
      font-size: 14px;
    }
    &__img {
      width: 52px;
      height: 44px;
      margin-right: 6px;
    }
  }
  .footer {
    &__text.email {
      margin: 18px 0 8px 0;
    }
    &__colum {
      &:nth-child(3) {
        width: 100%;
        min-width: 100%;
      }
    }
    &__row {
      &:nth-child(1) {
        margin-bottom: 30px;
        .footer__colum {
          &:nth-child(1) {
            margin-bottom: 24px;
          }
          &:nth-child(2) {
            max-width: 100%;
          }
          &:nth-child(3) {
            flex-direction: column;
            width: 100%;
            .soc-seti {
              margin-bottom: 30px;
            }
          }
        }
      }
      &:nth-child(2) {
        text-align: center;
        align-items: center;
      }
    }
    &__text {
      width: 100%;
    }
    &__list {
      width: 100%;
      justify-content: center;
      margin-bottom: 30px;
    }
    .select-box {
      margin: 10px 15px 0 0;
    }
    &__item:not(:last-child) {
      margin-right: 8px;
    }
    &__link {
      font-size: 14px;
    }
    .btn {
      margin: 0 auto;
    }
  }
  .wrapper, .wrapper-small, .wrapper-big {
    padding: 0 15px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .new-campaign-moodboard {
    grid-template-columns: repeat(2, 1fr);
  }
  .interests__svg {
    width: 64px;
    min-width: 64px;
  }
  .overview-header {
    &__left {
      margin: 0 0 130px;
    }
  }
  .overview-filter {
    .select-box {
      margin-top: 10px;
      max-width: calc((100% - 64px) / 2)!important;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
  .feedback-block__img {
    height: 44vw;
    margin-bottom: 12px;
  }
  .campaign__top-line {
    width: 11%;
  }
  .campaign__top {
    padding: 40px 0 80px;
  }
  .campaign__middle {
    margin: -48px 0 50px;
    grid-template-columns: repeat(2, 1fr);
  }
  .modal {
    padding: 0 15px 30px;
  }
  .modal-close {
    margin: -63px 15px 0 0;
  }
  .modal__cont {
    padding: 60px 30px 50px;
  }
}

@media screen and (max-width: 575px) {
  .new-campaign-moodboard {
    grid-template-columns: repeat(1, 1fr);
    &__box {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  .new-campaign-review__logo-group {
    padding-left: 16px;
  }
  .overview-header {
    &__text {
      &.big {
        font-size: 10px;
        line-height: 20px;
      }
      &.small {
        font-size: 10px;
        letter-spacing: 0.4px;
      }
    }
    &__number {
      &.big {
        font-size: 16px;
      }
      &.large {
        font-size: 14px;
        margin: 6px 0 4px;
      }
    }
    &__colum {
      &.left, &.right {
        width: 120px;
      }
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
    &__line {
      margin: 8px 0 12px;
      &-one, &-two {
        width: 20px;
      }
    }
    &__left {
      margin: 0 0 120px;
    }
  }
  .overview-filter {
    .wrapper {
      justify-content: flex-start;
    }
    &__select-group {
      flex-wrap: wrap;
    }
    .select-box {
      margin-top: 10px;
      &:first-child {
        max-width: 100%;
        margin-right: 0;
      }
      &:nth-child(2) {
        max-width: calc(100% - 48px);
      }
    }
  }
  .post-block {
    max-width: 348px;
    width: 100%;
    margin: 0 auto;
  }
  .overview-main .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .tile {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;
    padding: 22px;
    max-width: 344px;
  }
  .modal-wallet__cont {
    padding: 0;
  }
  .modal-wallet__bg {
    width: calc(100% + 30px);
    border-radius: 0;
  }
  .feedback-block__img {
    height: 84vw;
    margin-bottom: 12px;
    max-height: 370px;
  }
  .feedback-block {
    max-width: 348px;
    margin: 0 auto;
  }
  .feedback__cont {
    grid-template-columns: repeat(1, 1fr);
  }
  .campaign-box__img {
    height: 42vw;
    max-height: 240px;
  }
  .campaign-box {
    max-width: 440px;
    margin: 0 auto;
  }
  .campaign__top-line {
    width: 30px;
    min-width: 30px;
  }
  .campaign__middle {
    grid-template-columns: repeat(1, 1fr);
    margin: -34px 0 50px;
  }
  .campaign__top {
    padding: 30px 0 70px;
  }
  .campaign__cont .wrapper-big:nth-child(2) .wrapper {
    padding: 0 30px;
  }
  .modal {
    margin: 0;
    padding: 0;
  }
  .modal__cont {
    border-radius: 0;
    padding: 20px 15px 50px;
    min-height: 100vh;
  }
  .modal-close {
    margin: 15px 15px 0 0;
  }
  .modal__title {
    font-size: 24px;
    margin-bottom: 50px;
    margin-top: 76px;
  }
}

@media screen and (max-width: 420px) {
  .overview-header {
    &__row:nth-child(2) {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    &__colum.left, &__colum.right {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &__colum:not(:last-child) {
      margin-right: 0;
    }
    &__colum.left {
      margin: 0 0 12px;
    }
    &__colum.right {
      margin: 12px 0 0;
      br {
        display: none;
      }
      .overview-header__line {
        align-self: center;
      }
    }
    &__line {
      margin: 0;
    }
    &__text.big {
      max-width: 100px;
    }
    &__left {
      margin: 0 0 204px;
    }
    &__bg {
      height: 320px;
    }
  }
}

@media screen and (max-width: 350px) {
  .campaign__top-line {
    display: none;
  }
}
