footer {
    padding-bottom: 50px;
}
.footer {
    position: relative;
    .logo__text {
        color: var(--footer-logo-text-color);
    }
    &__row {
        display: flex;
        justify-content: space-between;
        &:first-child {
            margin-bottom: 18px;
        }
    }
    &__colum {
        display: flex;
        align-items: center;
        &:nth-child(1) {
            width: 270px;
            min-width: 270px;
        }
        &:nth-child(2) {
            max-width: 622px;
            width: 100%;
            margin: 0 32px;
        }
        &:nth-child(3) {
            justify-content: space-between;
            width: 348px;
            min-width: 348px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-right: auto;
    }
    &__item {
        display: flex;
        &:not(:last-child) {
            margin-right: 32px;
        }
    }
    &__link {
        font-size: 16px;
        line-height: 1;
        padding: 4px;
        color: var(--tertiary-text-color);
        position: relative;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            transform-origin: 50% 50%;
            transform: scale(0);
            width: 100%;
            height: 1px;
            background-color: currentColor;
        }
    }
    .select-box {
        font-size: 16px;
        line-height: 1;
        padding: 4px;
        color: var(--tertiary-text-color);
        cursor: pointer;
        margin-left: 32px;
    }
    &__text {
        font-size: 14px;
        color: var(--blue);
        line-height: 1.3;
        align-self: flex-end;
        &.email {
            margin: 0 0 18px 74px;
        }
    }
}
