.ngneat-dialog-content {
  overflow: visible!important;
}
.overlay, .overlay-menu, .ngneat-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(71, 19, 107, 0.5)!important;
    backdrop-filter: blur(4px);
    display: none;
    overflow: auto;
}
// not use
.modal {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    margin: 76px 0 0;
    &-small {
        max-width: 1092px;
        padding: 0 46px 30px;
    }
    &__cont {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
        border-radius: 10px;
        padding: 60px 50px 50px;
    }
    &__title {
        font-family: 'KronaOne', sans-serif;
        font-size: 48px;
        text-align: center;
        margin-bottom: 60px;
        line-height: 1.1;
    }
    &-close {
        position: absolute;
        right: -50px;
        top: 0;
        width: 36px;
        height: 36px;
        margin: -46px 0 0 0;
        cursor: pointer;
    }
    .btn {
        margin: 0 auto;
    }
}
// not use



.modal-block {
  &-small {
    max-width: 1092px;
    padding: 0 46px 30px;
  }
  &__cont {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
    border-radius: 10px;
    padding: 60px 50px 50px;
  }
  &__title {
    font-family: 'KronaOne', sans-serif;
    font-size: 48px;
    text-align: center;
    margin-bottom: 60px;
    line-height: 1.1;
  }
  &-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 46px;
    height: 46px;
    margin: -46px 0 0 0;
    cursor: pointer;
  }
  .btn {
    margin: 0 auto;
  }
}




.modal-brand-login {
    .form {
        position: relative;
    }
    .form__group--two:nth-child(1) {
        .btn-text__text {
            font-size: 18px;
        }
    }
    .form__group--two:nth-child(2) {
        .btn-text {
            margin-left: auto;
        }
        .btn-text__text {
            font-size: 14px;
        }
    }
    .btn {
        margin: 20px auto 0;
    }
    .form__box {
        margin-bottom: 18px;
    }
    .btn-text {
        height: 20px;
        &__text {
            text-transform: uppercase;
            line-height: 1;
        }
        svg {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }
}
