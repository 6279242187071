*, *:before, *:after {
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -o-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
h1,h2,h3,span,p,b {
  cursor: default;
}
ul, ol {
  list-style: none;
}
a {
  text-decoration: unset;
}
svg, img {
  width: 100%;
  height: 100%;
}
button, input, textarea {
  font-family: 'OpenSans', sans-serif;
}
button *, a * {
  cursor: pointer;
}
button {
  cursor: pointer;
  border: unset;
}
body {
  font-family: 'OpenSans', sans-serif;
  background: var(--main-bg);
  color: var(--primary-text-color);
  font-size: 20px;
  line-height: 1.5;
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
footer {
  margin-top: auto;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: 5000000s ease-in-out 0s;
}
button, .btn, .btn-text, .soc-seti__link, .menu__link, .checked, .campaign-arrow, .switch, .filter-arrow, select, .filter-soc-net__item, .steps__btn, .tile__box {
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.wrapper {
  max-width: 1504px;
}
.wrapper, .wrapper-small, .wrapper-big {
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
}
.wrapper-small {
  max-width: 1322px;
}
.wrapper-big {
  max-width: 1864px;
}
.wrapper-big.search {
  height: 90px;
  margin-bottom: 50px;
}
.search {
  .campaign__top {
    padding: 18px 100px;
    align-items: center;
  }
  form {
    width: 100%;
  }
  .interests-button {
    margin: 0 30px;
  }
  .search-interests {
    justify-content: space-between;
    label {
      display: flex;
      padding: 10px 0;
    }
    .checked__text {
      color: white;
    }
  }
}

h2 {
  font-size: 60px;
  font-weight: 700;
  color: var(--tertiary-text-color);
  line-height: 1;
  margin-bottom: 36px;
  text-transform: uppercase;
}
h3 {
  font-family: 'KronaOne', sans-serif;
  font-size: 24px;
  color: var(--tertiary-text-color);
  font-weight: 400;
}
.title-krona {
  font-family: 'KronaOne', sans-serif;
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 50px;
  display: block;
  a {
    color: var(--primary-text-color);
  }
}
.title-group {
  max-width: 1258px;
  margin: 0 auto;
}


.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color .1s linear, color .1s linear;
  &__text {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
  }
  &.small {
    width: 200px;
    height: 50px;
  }
  &.big {
    max-width: 348px;
    width: 100%;
    height: 70px;
  }
}
.btn-text {
  display: flex;
  height: 26px;
  align-items: center;
  background-color: transparent;
  transition: color .1s linear;
  &__text {
    font-size: 1rem;
    font-weight: 600;
  }
  &__svg {
    margin-left: 8px;
  }
  &__svg-left {
    margin-right: 8px;
  }
  &__svg, &__svg-left {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
  &__svg-back {
    width: 8px;
    min-width: 8px;
    height: 16px;
    margin-right: 22px;
  }
}
.btn-circle {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: center;
  &__svg {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
  &__text {
    margin-top: 6px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5;
  }
}
.pay-pal-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.information {
  display: flex;
  width: 12px;
  height: 12px;
  cursor: pointer;
  &__campaign-wallet {
    position: absolute;
    bottom: 20px;
    right: 0;
    color: white!important;
  }
}
.mb-160 {
  margin-bottom: 160px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-20 {
  margin-bottom: 20px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pt-100 {
  padding-top: 100px;
}


.soc-seti__link, .form__input, .form__textarea, .form__error, .form__title, .checked__square:after, .checked__square, .checked__text, .modal-close, .btn-circle,
.pay-pal-btn, .campaign-box__hover, .filter-arrow, .filter-soc-net__item:after, .steps__btn, .information, .upload__add, .upload__icons, .upload__text, .interests,
.interests__text, .interests__svg
{
  transition: .1s linear;
}
.campaign-box__img {
  transition: .15s linear;
}
.menu__link:after, .footer__link:after {
  transition: .2s ease;
}
.disabledPostButtons {
  color: gray!important;
  pointer-events: none;
  cursor: none;
}
@media (hover: hover) {
  .menu__link:hover:after, .footer__link:hover:after {
    transform: scale(1);
  }
}
