@mixin button-filled($bg-color, $color, $hover) {
    background-color: $bg-color;
    border-radius: 5px;
    color: $color;
    &:hover {
        background-color: $hover;
    }
    &[disabled] {
        background-color: var(--disabled);
        pointer-events: none;
    }
}
@mixin button($color, $hover, $radius) {
    border: 2px solid currentColor;
    border-radius: $radius;
    color: $color;
    &:hover {
        color: $hover;
    }
    &[disabled] {
        color: var(--disabled);
        pointer-events: none;
    }
}
@mixin button-text($color, $hover) {
    color: $color;
    &:hover {
        color: $hover;
    }
    &[disabled] {
        color: var(--disabled);
        pointer-events: none;
    }
}