.profile {
    overflow: hidden;
    &__header {
        margin-bottom: 36px;
        align-items: center;
        h2 {
            margin-bottom: 0;
        }
    }
    .wrapper-small {
        display: flex;
        justify-content: space-between;
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .btn-text__text {
        white-space: nowrap;
    }
    .switch {
        margin-top: 30px;
    }
}
.profile-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    .btn-text__text {
        font-size: 14px;
    }
    &-form {
      display: contents;
    }
    @media screen and (min-width: 1025px) {
        &--w-54 {
            width: 54%;
        }
        &--w-46 {
            width: 46%;
        }
        &--w-54, &--w-46 {
            .profile-block__colum {
                max-width: 100%;
            }
            .profile-block__middle {
                padding: 0 40px;
            }
        }
        &--right {
            .profile-block__top {
                justify-content: flex-end;
            }
            .profile-block__middle {
                align-self: flex-end;
            }
            .profile-block__main-title {
                padding-right: 0;
                margin-right: 0;
            }
            .form__title {
                padding-left: 0!important;
            }
        }
        &--left {
            .switch {
                margin-left: 20px;
            }
        }
    }
    &:not(:last-child) {
        .profile-block__middle {
            margin-bottom: 46px;
        }
    }
    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 46px;
    }
    &__line {
        width: 178px;
        height: 2px;
        background-color: currentColor;
        align-self: flex-end;
        margin: 0 46px 5px 0;
    }
    &__main-title {
        font-family: 'KronaOne', sans-serif;
        font-size: 36px;
        line-height: 1;
        padding-right: 20px;
        margin-right: auto;
    }
    &__middle {
        display: flex;
        justify-content: space-between;
        padding: 0 90px 0 40px;
        &.no-edit {
            .form__input {
                box-shadow: unset;
                pointer-events: none;
                background-color: transparent;
            }
        }
    }
    &__colum {
        display: flex;
        flex-direction: column;
        max-width: 348px;
        width: 100%;
        margin-left: 30px;
        .btn-text {
            align-self: flex-start;
            margin-left: 20px;
        }
    }
    .form__box:not(:last-child) {
        margin-bottom: 20px;
    }
    .form__title {
        font-family: 'KronaOne', sans-serif;
        color: var(--tertiary-text-color);
        padding-left: 20px;
        text-transform: none;
    }
}
.profile-block-bar {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    padding: 50px 0 38px;
    .profile-block {
        color: var(--secondary-text-color);
        &__top, &__middle {
            z-index: 2;
        }
        .form__title {
            color: var(--secondary-text-color);
        }
        &__middle.no-edit .form__input {
            color: var(--secondary-text-color);
        }
        &:not(:last-child) .profile-block__middle {
            margin-bottom: 0;
        }
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        width: 1800px;
        height: 100%;
    }
}
