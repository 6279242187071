.overview-header {
    position: relative;
    padding-bottom: 36px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 2;
    .wrapper {
        display: flex;
        position: relative;
        z-index: 2;
    }
    h2 {
        margin: 26px 0;
        font-size: 36px;
        padding-right: 20px;
    }
    &__left, &__right {
        display: flex;
        flex-direction: column;
    }
    &__left {
        margin-right: 42px;
        width: 44vw;
        max-width: 440px;
    }
    &__right {
        width: 100%;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        &:nth-child(1) {
            align-items: center;
        }
        &:nth-child(2) {
            padding: 28px 0;
        }
    }
    &__img {
        height: 240px;
        object-fit: cover;
        box-shadow: 0px 10px 25px -15px rgba(27, 0, 46, 0.5);
        border-radius: 10px;
    }
    &__open-until {
        font-size: 12px;
        margin: 10px 0;
        text-align: center;
        text-transform: uppercase;
    }
    .btn {
      max-width: 258px;
      width: 100%;
      opacity: 1;
      &__hidden {
        opacity: 0.5;
        border: 2px solid;
        pointer-events: none;
        cursor: none;
      }
      &__text {
        font-size: 14px;
      }
    }
    &__bg {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1800px;
        height: 190px;
        object-fit: cover;
        box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
        border-radius: 10px;
        margin-bottom: 36px;
    }
    &__colum {
        position: relative;
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
            margin-right: 16px;
        }
        &.left {
            width: 240px;
        }
        &.right {
            width: 220px;
            text-align: right;
            .overview-header__line {
                align-self: flex-end;
            }
        }
    }
    &__line {
        width: 100px;
        height: 1px;
        background-color: currentColor;
        margin: 14px 0 18px;
        &-one, &-two {
            width: 40px;
            height: 3px;
        }
    }
    &__text {
        &.big {
            font-family: 'KronaOne', sans-serif;
            font-size: 24px;
            line-height: 1.4;
        }
        &.small {
            text-transform: uppercase;
            font-size: 14px;
            line-height: 1;
        }
    }
    &__number {
        font-family: 'KronaOne', sans-serif;
        line-height: 1;
        white-space: nowrap;
        &.big {
            font-size: 24px;
        }
        &.large {
            font-size: 20px;
            margin: 6px 0;
        }
    }
    &__gorup {
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
            margin-bottom: 14px;
        }
    }
}
.overview-filter {
    position: sticky;
    top: 0;
    padding-top: 54px;
    background-color: var(--main-bg);
    margin-top: -40px;
    z-index: 10;
    .wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        padding-bottom: 26px;
        padding-top: 22px;
    }
    &__line {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 64px);
        height: 2px;
    }
    &__btn-group, &__select-group {
        display: flex;
    }
    &__btn-group {
        .btn {
            width: 210px;
            height: 56px;
            &:first-child {
                margin-right: 20px;
            }
            &__text {
                font-size: 14px;
            }
        }
    }
    &__select-group {
        margin: 0 12px 0 20px;
        max-width: 596px;
        width: 100%;
        .select-box {
            &:first-child {
                max-width: 280px;
                margin-right: 20px;
            }
            &:nth-child(2) {
                max-width: 250px;
            }
        }
        .filter-arrow {
            align-self: flex-end;
            margin: 0 0 10px 12px;
        }
    }
    .select-box {
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;
    }
}
.select {
    width: 100%;
    height: 56px;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        padding: 3px;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        margin-right: 26px;
        pointer-events: none;
    }
    select {
        width: 100%;
        border-radius: 5px;
        border: 1px solid transparent;
        box-shadow: inset 0px 4px 13px -2px rgba(13, 11, 109, 0.1);
        font-size: 16px;
        height: 100%;
        padding: 0 42px 0 20px;
        color: currentColor;
        // cursor: pointer; TODO: check the cursor style
    }
}
.filter-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    min-width: 36px;
    height: 36px;
    background-color: transparent;
    align-self: flex-end;
    margin: 0 0 10px 12px;
    &__svg {
        width: 18px;
    }
}
.filter-soc-net {
    display: flex;
    flex-direction: column;
    max-width: 340px;
    width: 100%;
    &__box {
        display: flex;
        margin-left: -6px;
        justify-content: space-between;
    }
    &__item {
        height: 56px;
        width: 56px;
        padding: 6px;
        position: relative;
        cursor: pointer;
        display: flex;
        &:not(:last-child) {
            margin-right: 6px;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            opacity: 0;
        }
        &.active:after {
            opacity: 1;
        }
        img {
          align-self: center;
        }
        &.reels {
          img {
            height: 39px;
          }
        }
    }
}
.overview-main {
    padding-top: 26px;
    .wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
    }
}
.post-block {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 20px 20px 0;
    box-shadow: 0 10px 25px -25px rgba(27, 0, 46, 0.25);
    cursor: pointer;
    position: relative;
    * {
        cursor: pointer;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 250px;
        width: 100%;
    }
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }
    &__icon {
        width: 34px;
        min-width: 34px;
        height: 34px;
        object-fit: contain;
        margin-right: 6px;
    }
    &__group {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }
    &__updated {
      width: 25px;
      height: 25px;
      background: url("~src/assets/img/common/updated_post.png") no-repeat;
      background-size: contain;
      border-radius: 100%;
      right: 20px;
      position: absolute;
    }
    &__seen {
        width: 10px;
        height: 10px;
        background: var(--main-iol-light);
        border-radius: 100%;
        right: 20px;
        position: absolute;
    }
    &__info {
        font-size: 12px;
        line-height: 1;
        &:first-child {
            margin-bottom: 4px;
        }
        b {
            font-weight: 600;
        }
      a {
        font-weight: bold;
        padding-top: 5px;
        font-size: 14px;
        display: flex;
      }
    }
    &__price {
        margin-left: auto;
        font-size: 24px;
        font-weight: 600;
    }
    &__bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 8px 0;
        .btn {
            width: 40px;
            height: 20px;
            &__text {
                font-size: 12px;
                line-height: 1;
            }
        }
    }
    &__text {
        font-size: 10px;
        margin-right: 6px;
    }
}
.post-card {
    display: flex;
    flex-direction: column;
    background-color: var(--main-bg);
    border-radius: 5px;
    box-shadow: 0px 10px 25px -25px rgba(27, 0, 46, 0.15);
    &__top {
        display: flex;
        padding: 9px 7px;
    }
    &__avatar {
        width: 46px;
        min-width: 46px;
        height: 46px;
        border-radius: 50%;
        object-fit: cover;
    }
    &__gorup {
        display: flex;
        flex-direction: column;
        padding-left: 6px;
        width: calc(100% - 46px);
    }
    &__name {
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        margin: 6px 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    &__date {
        font-size: 10px;
        line-height: 1;
        font-weight: 600;
    }
    &__wrap-img {
      position: relative;
    }
    &__middle-img {
        height: 300px;
        object-fit: cover;
    }
    &__video-icon-img {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    &__bottom {
        display: flex;
        flex-direction: column;
        padding: 8px 18px 10px;
    }
    &__description {
        height: 30px;
        font-size: 10px;
        font-weight: 600;
        line-height: 1.4;
        margin-bottom: 10px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    &__btn-group {
        display: flex;
        margin: 0 auto;
        pointer-events: none;
    }
    .btn-circle {
        margin: 0 5px;
        &__svg {
            width: 30px;
            height: 30px;
            min-width: 30px;
        }
    }
}
.insights-link{
  position: absolute;
  bottom: 50px;
  img{
    width: 20px;
    float: left;
    padding: 5px 5px 0 0;
  }
  a{
    float: left;
    &:hover {
      text-decoration: underline;
    }
  }
  &.three-items{
    bottom: 80px;
  }
}
.download-link{
  position: absolute;
  bottom: 50px;
  a{
    float: left;
    &:hover {
      text-decoration: underline;
    }
  }
  &.new-tab-page{
    bottom: 20px;
  }
}
.new-tab-link{
  position: absolute;
  bottom: 20px;
  a{
    float: left;
    &:hover {
      text-decoration: underline;
    }
  }
}
.progress-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8%;
}
.campaign-review{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 100px 0;
  flex-direction: column;

  .post-info{
    justify-content: center;
    position: relative;
  }
}

.post-tab-navigation-buttons{
  padding-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;

  .post-tab__navigate-button{
    font-size: 21px;
    color: var(--tertiary-text-color);
  }
}
