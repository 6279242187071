/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '~ngx-toastr/toastr';

@import "assets/styles/variables";
@import "assets/styles/variables-dashboard";
@import "assets/styles/fonts";
@import "assets/styles/base";
@import "assets/styles/base-internal";

@import "assets/styles/components/footer";
@import "assets/styles/components/header";
@import "assets/styles/components/header-internal";
@import "assets/styles/components/form";
@import "assets/styles/components/form-internal";
@import "assets/styles/components/home-page";
@import "assets/styles/components/contact";

@import "assets/styles/components/insights";
@import "assets/styles/components/modal";

@import "assets/styles/components/modal-internal";
@import "assets/styles/components/campaigns";
@import "assets/styles/components/campaign-overview";
@import "assets/styles/components/my-campaign-overview__feedback";
@import "assets/styles/components/campaign-overview-additional";
@import "assets/styles/components/my-profile";
@import "assets/styles/components/new-campaign";

@import "assets/styles/media";
@import "assets/styles/media-dashboard";

.ngneat-dialog-content {
  width: auto!important;
}

.mat-tooltip {
  /* your own custom styles here */
  /* e.g. */
  background-color: var(--tertiary-text-color);
  font-size: 11px;
}

