.form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    &--w-740 {
        max-width: 740px;
        margin: 0 auto;
    }
    &__box {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        color: var(--primary-form-text-color);
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    &__group--two, &__group--three {
        margin-bottom: 30px;
    }
    &__group--two {
        width: calc((100% - 40px) / 2);
    }
    &__group--three {
        width: calc((100% - 80px) / 3);
    }
    &__title {
        font-size: 16px;
        color: currentColor;
        line-height: 1;
        margin-bottom: 4px;
        text-transform: uppercase;
    }
    &__input {
        height: 56px;
        padding: 0 20px;
    }
    &__input, &__textarea {
        width: 100%;
        border-radius: 5px;
        border: 1px solid transparent;
        box-shadow: inset 0px 4px 13px -2px rgba(13, 11, 109, 0.1);
        background-color: #fff;
        font-size: 16px;
    }
    &__textarea {
        height: 150px;
        padding: 18px 20px;
        resize: none;
    }
    &__error {
        font-size: 12px;
        line-height: 1.4;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 2px;
        pointer-events: none;
    }
    &__checked {
        display: flex;
        width: 100%;
        margin: 10px 0 50px;
    }
  &_main_error {
    font-size: 14px;
    color: red;
    display: flex;
    align-self: center;
    margin-top: 10px;
  }
}
.checked {
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
        display: none;
        &:checked + .checked__square:after {
            opacity: 1;
        }
    }
    &__square {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 16px;
        min-width: 16px;
        height: 16px;
        border: 1px solid transparent;
        border-radius: 5px;
        margin-right: 6px;
        box-shadow: inset 0px 4px 13px -2px rgba(13, 11, 109, 0.1);
        cursor: pointer;
        &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 4px;
            pointer-events: none;
            opacity: 0;
        }
    }
    &__text {
        cursor: pointer;
        font-size: 12px;
    }
}
