@import "mixins";
:root {

  --black: #242425;
  --main-iol: #232227;
  --main-iol-hover: #4c3ef6;
  --main-iol-light: #a9a7fc;
  --green: #3929ef;
  --green-hover: #3929ef;
  --white: #fff;
  --purple: #2b0052;
  --white-hover: #a9a7fc;
  --blue: #1604DF;
  --blue-hover: #3929ef;
  --blue-white: #86cdf1;
  --blue-light: var(--white-hover);
  --blue-light-hover: #C5CCFF;
  --gray: #EBEEFF;
  --red: #E2414B;
  --disabled: #EBEEFF;

  --main-bg: var(--white);

  --primary-logo-text-color: var(--white);
  --secondary-logo-text-color: #AC22F7;
  --footer-logo-text-color: var(--blue-light);

  --primary-gradient-header: linear-gradient(90deg, #4558A7 0%, #454396 100.65%);

  --primary-text-color: var(--black);
  --secondary-text-color: var(--white);
  --tertiary-text-color: var(--main-iol);

  // Home page
  --come-attivare-title-color: #4355A3;
  --come-attivare-color-line: #0D0B6D;
  --hero-navigation-color: #7988EF;

  // Footer
  --footer-text-light-blue: var(--blue-light);

  // Form
  --primary-form-text-color: var(--main-iol);
  --secondary-form-text-color: var(--white);


  .primary-btn {
    @include button(var(--green), var(--green-hover), 5px);
  }
  .secondary-btn {
    @include button(var(--main-iol), var(--main-iol-hover), 5px);
  }
  .tertiary-btn {
    @include button(var(--white), var(--blue-light-hover), 5px);
  }
  .cookie-policy-btn {
    @include button(var(--blue-light), var(--blue-light-hover), 5px);
  }

  .primary-btn-filled {
    @include button-filled(var(--blue), var(--white), var(--blue-hover));
  }
  .secondary-btn-filled {
    @include button-filled(var(--main-iol), var(--white), var(--main-iol-hover));
  }

  .primary-btn-text {
    @include button-text(var(--main-iol), var(--main-iol-hover));
  }
  .secondary-btn-text {
    @include button-text(var(--white), var(--blue-light));
  }
  .tertiary-btn-text {
    @include button-text(var(--white), var(--white-hover));
  }



  // Form
  .form__box {
    &.error {
      .form__title {
        color: var(--red);
      }
      .form__input, .form__textarea {
        border-color: var(--red);
      }
      .form__error {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &.success {
      .form__title {
        color: var(--green);
      }
      .form__input, .form__textarea {
        border-color: var(--green);
      }
    }
    .form__error {
      color: var(--red);
    }
  }
  .checked {
    color: var(--primary-form-text-color);
    &.error {
      .checked__square {
        border-color: var(--red);
      }
      .checked__text {
        color: var(--red);
      }
    }
    &__square {
      background-color: var(--white);
      &:after {
        background-color: var(--primary-form-text-color);
      }
    }
    a {
      color: var(--green);
    }
  }
  .contact {
    .form__box, .checked {
      color: var(--secondary-form-text-color);
    }
  }


  // Home Page
  .menu-btn__hamburger {
    background: var(--green);
    &:before, &:after {
      background: var(--green);
    }
    &.active {
      &:before, &:after {
        background: var(--main-iol-hover);
      }
    }
  }
  .selector {
    &__name {
      color: var(--green);
      &:hover {
        color: var(--green-hover);
      }
      &.active {
        color: var(--white);
        cursor: default;
      }
    }
    &__line {
      background-color: var(--white);
    }
  }
  .hero-navigation {
    &__item {
      background-color: var(--white);
      &.active {
        background-color: var(--hero-navigation-color);
        cursor: default;
      }
    }
  }
  .slider-community .slick-prev, .slider-community .slick-next, .nostra-community .slick-prev, .nostra-community .slick-next{
    color: var(--main-iol);
    &:active {
      color: var(--main-iol-hover);
    }
    @media (hover: hover) {
      &:hover {
        color: var(--main-iol-hover);
      }
    }
    @media screen and (max-width: 767px) {
      color: var(--white);
      &:active {
        color: var(--blue-light-hover);
      }
    }
    &[disabled] {
      color: var(--disabled);
      pointer-events: none;
    }
  }
  .soc-seti {
    &__link {
      color: var(--blue-light);
      @media (hover: hover) {
        &:hover {
          color: var(--main-iol);
        }
      }
      &:active {
        color: var(--main-iol);
      }
    }
  }


  // FAQ Page
  .video-box__group-btn {
    .btn.active {
      border-color: var(--green);
      background-color: var(--green);
      color: var(--white);
    }
  }
  .accordion {
    &__block {
      border-bottom: 1px solid var(--gray);
    }
    &__link {
      color: var(--main-iol-light);
    }
    .plus-circle {
      color: var(--main-iol-light);
      &.active {
        color: var(--gray);
      }
    }
  }


  // Influencer Page
  .simple-as {
    &__item {
      &.active {
        color: var(--main-iol-light);
        &:before {
          color: var(--blue);
        }
      }
      &:after {
        background-color: var(--white);
      }
    }
  }


  // Insights Page
  .insights {
    &-bar-top {
      &__group {
        color: var(--blue-light-hover);
      }
    }
    &-bar {
      &__number {
        color: var(--green);
      }
      &__line {
        background-color: var(--white);
      }
    }
  }


  // La piattaforma
  .notebook-block {
    &__title {
      color: var(--white);
    }
    &__group {
      color: var(--white);
    }
  }
  .honor {
    color: var(--white-hover);
  }


  // Risultati garantiti
  .perche-sponsorizzare-list {
    &__item {
      color: var(--white)
    }
  }


  // Saas white label
  .saas-text {
    &__text {
      &.color {
        color: var(--main-iol)
      }
    }
  }
  .qualche-block {
    &__colum {
      color: var(--white);
    }
  }


  // Modals
  .modal {
    &__cont {
      background-color: var(--white);
    }
    &-close {
      color: var(--white);
      @media (hover: hover) {
        &:hover {
          color: var(--white-hover);
        }
      }
      @media screen and (max-width: 575px) {
        color: var(--main-iol-hover);
      }
    }
  }
}
