*, *:before, *:after {
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -o-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
h1,h2,h3,span,p,b {
  cursor: default;
}
ul, ol {
  list-style: none;
}
a {
  text-decoration: unset;
}
svg, img {
  width: 100%;
  height: 100%;
}
button, input, textarea {
  font-family: 'OpenSans', sans-serif;
}
button *, a * {
  cursor: pointer;
}
button {
  cursor: pointer;
  border: unset;
}
body {
  font-family: 'OpenSans', sans-serif;
  background: var(--main-bg);
  color: var(--primary-text-color);
  font-size: 20px;
  line-height: 1.5;
  min-width: 320px;
  position: relative;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: 5000000s ease-in-out 0s;
}
button, .btn, .btn-text, .selector__name, .soc-seti__link, .menu__link, .checked {
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.menu__link {
  cursor: pointer;
}
.wrapper {
  max-width: 1504px;
}
.wrapper, .wrapper-small {
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
}
.wrapper-small {
  max-width: 1322px;
}

h2 {
  font-size: 60px;
  font-weight: 700;
  color: var(--tertiary-text-color);
  line-height: 1;
  margin-bottom: 36px;
  text-transform: uppercase;
}
h3 {
  font-family: 'KronaOne', sans-serif;
  font-size: 24px;
  color: var(--tertiary-text-color);
  font-weight: 400;
}
.title-krona {
  font-family: 'KronaOne', sans-serif;
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 50px;
  display: block;
  a {
    color: var(--primary-text-color);
  }
}
.title-group {
  max-width: 1258px;
  margin: 0 auto;
}


.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color .1s linear, color .1s linear;
  &__text {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
  }
  &.small {
    width: 200px;
    height: 50px;
  }
  &.big {
    max-width: 348px;
    width: 100%;
    height: 70px;
  }
}
.btn-text {
  display: flex;
  height: 26px;
  align-items: center;
  background-color: transparent;
  transition: color .1s linear;
  &__text {
    font-size: 1rem;
    font-weight: 600;
  }
  &__svg {
    max-width: 18px;
    height: 20px;
    margin-left: 8px;
  }
}
.mb-160 {
  margin-bottom: 160px;
}
.mb-130 {
  margin-bottom: 130px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-50 {
  margin-top: 50px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-70 {
  padding-top: 70px;
}


.selector__name, .soc-seti__link, .slick-prev, .slick-next, .form__input, .form__textarea, .form__error, .form__title, .simple-as__item,
.simple-as__item:before, .checked__square:after, .checked__square, .checked__text, .modal-close
{
  transition: .1s linear;
}
.menu__link:after, .footer__link:after {
  transition: .2s ease;
}

@media (hover: hover) {
  .menu__link:hover:after, .footer__link:hover:after {
    transform: scale(1);
  }
  .soc-seti__link:hover:after {
    opacity: 1;
  }
}
// home page and footer  icons
.soc-seti {
  display: flex;
  &__link {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    position: relative;
    &:not(:last-child) {
      margin-right: 26px;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  &__img {
    width: 62px;
    object-fit: contain;
    height: 54px;
    margin-right: 12px;
  }
  &__text {
    font-family: 'KronaOne', sans-serif;
    font-size: 16px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
  }
}

.typical-page {
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  h1 {
    margin: 32px 0;
    text-align: center;
    &.big {
      font-size: 140px;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    min-height: 460px;
    position: relative;
    padding: 78px 32px;
  }
  &__title, &__subtitle {
    font-family: 'KronaOne', sans-serif;
    text-align: center;
    color: white;
  }
  &__title {
    font-size: 48px;
    text-transform: uppercase;
  }
  &__subtitle {
    font-size: 30px;
    margin-top: 36px;
  }
  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    &.max-660 {
      max-width: 660px;
      width: 100%;
    }
  }
  .btn, &__group {
    z-index: 2;
  }
  .btn {
    margin-top: auto;
    cursor: pointer;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
  }
}
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 32px;
  margin-bottom: 32px;
  width: 360px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  z-index: 99;
  &__title, .btn {
    z-index: 2;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 16px;
  }
  .btn {
    width: 120px;
    height: 36px;
    &__text {
      font-size: 14px;
    }
  }
}
.overlay, .overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(71, 19, 107, 0.5);
  backdrop-filter: blur(4px);
  display: none;
  overflow: auto;
}
.toast-bottom-right {
  bottom: 100px;
}

