@import '~swiper/swiper-bundle';
.overlay, .overlay-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(71, 19, 107, 0.5);
    backdrop-filter: blur(4px);
    display: none;
    overflow: auto;
}
.modal {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    padding: 0 46px 30px;
    margin: 76px 0 0;
    &-big {
        max-width: 1532px;
    }
    &-less-small {
        max-width: 1000px;
    }
    &-small {
        max-width: 1092px;
    }
    &__cont {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
        border-radius: 10px;
        padding: 60px 50px 50px;
    }
    &__title {
        font-family: 'KronaOne', sans-serif;
        font-size: 48px;
        text-align: center;
        margin-bottom: 60px;
        line-height: 1.1;
    }
    &-close {
        position: absolute;
       // right: 0;
        top: 0;
        width: 46px;
        height: 46px;
        margin: -46px 0 0 0;
        cursor: pointer;
    }
    .btn {
        margin: 0 auto;
    }
}
.modal-contact-brand {
    .form {
        max-width: 1070px;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        align-items: center;
    }
}
.contact-brand {
    &__cont {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1261px) {
            align-items: center;
        }
    }
    &__tile-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: calc(100% + 10px);
        margin: 0 -5px;
    }
    &__price {
        display: flex;
        align-items: center;
        padding: 22px 20px;
        justify-content: space-between;
        margin: 0 0 60px;
        position: relative;
        @media screen and (max-width: 1261px) {
            max-width: 350px;
            width: 100%;
        }
    }
    &__text-info {
        position: absolute;
        top: 100%;
        left: 0;
        font-size: 10px;
        line-height: 1;
        margin-top: 10px;
        margin-left: 20px;
    }
    &__text {
        font-size: 16px;
        line-height: 1;
    }
    &__box {
        display: flex;
        flex-direction: column;
        max-width: 350px;
        width: 100%;
        .form__box:not(:last-child) {
            margin-bottom: 8px;
        }
        .btn {
            margin-top: 32px;
        }
        .checked {
            align-self: flex-start;
        }
    }
}
.contact-brand-tile {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    margin: 0 5px 10px;
    &__img {
        height: 280px;
        object-fit: cover;
        margin-bottom: 10px;
    }
    &__group {
        display: flex;
    }
    &__colum {
        display: flex;
        align-items: center;
        padding: 10px;
        &:nth-child(1) {
            width: calc(100% - 134px);
            padding-left: 20px;
        }
        &:nth-child(2) {
            width: 130px;
            margin-left: 4px;
            justify-content: flex-end;
        }
    }
    &__text {
        font-size: 16px;
        line-height: 1;
    }
    .form__input {
        width: 80px;
        height: 50px;
        border: unset;
        padding: 0 10px 0 6px;
        text-align: right;
        margin-left: 4px;
    }
}
.post-info {
    display: flex;
    min-height: 500px;
    &__colum {
        display: flex;
        flex-direction: column;
        width: 100%;

        &:nth-child(1) {
            max-width: 352px;
            margin-right: 50px;
            min-width: 250px;
        }
        &:nth-child(2), video {
            max-width: 406px;
        }
    }
    &__btn-group {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
    &__img {
        height: 53vw;
        max-height: 530px;
        margin-bottom: 20px;
        object-fit: cover;
    }
    &__submitted {
        display: flex;
        margin-top: 16px;
        padding-top: 20px;
        font-size: 10px;
        line-height: 1;
        b {
            font-weight: 600;
            margin-left: 6px;
        }
        &:nth-child(3) {
          padding: unset;
          border-top: unset;
        }
    }
}
.modal__button{
  padding: 10px 30px;
  margin: 0 auto 30px auto;
  text-transform: uppercase;
  font-weight: bold;
}
.modal-post-info {
    margin-bottom: 46px;
}
.post-info-price {
    display: flex;
    margin-bottom: 4px;
    min-height: 40px;
    &__colum {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:nth-child(1) {
            width: calc(100% - 136px);
            padding: 5px 10px 7px 10px;
        }
        &:nth-child(2) {
            width: 132px;
            margin-left: 4px;
            align-items: flex-end;
            padding: 0 20px 0 10px;
        }
    }
    &__text {
        font-size: 16px;
        line-height: 18px;
    }
    &__subtext {
        font-size: 10px;
        line-height: 1;
    }
}
.modal-wallet {
    &__cont {
        max-width: 1200px;
        width: 100%;
        padding: 0 30px;
        margin: 0 auto;
    }
    &__bar {
        display: flex;
        padding: 34px 0;
        position: relative;
        align-items: flex-end;
        color: var(--secondary-text-color);
        margin-bottom: 20px;
        .modal-wallet__cont {
            display: flex;
            align-items: flex-end;
        }
        .modal-wallet__colum {
            z-index: 2;
            &:nth-child(1) {
                width: 394px;
                padding-right: 42px;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100px;
                    height: 1px;
                    background-color: #fff;
                }
            }
            &:nth-child(2) {
                width: calc(100% - 394px);
            }
        }
        .form__title {
            line-height: 22px;
            color: var(--secondary-text-color);
        }
        .form__box {
            margin-bottom: 20px;
        }
        .form__input {
            color: var(--campagin-primary-text-color);
        }
    }
    &__colum {
        display: flex;
        flex-direction: column;
    }
    &__title {
        font-family: 'KronaOne', sans-serif;
        font-size: 24px;
        line-height: 1.4;
    }
    &__price {
        font-family: 'KronaOne', sans-serif;
        font-size: 36px;
        line-height: 1;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        border-radius: 10px;
        object-fit: cover;
    }
    &__text {
        display: flex;
        font-size: 16px;
        color: var(--campagin-primary-text-color);
        line-height: 24px;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        .modal-wallet__text {
            &:first-child {
                padding-right: 16px;
            }
            &:last-child {
                text-align: right;
            }
        }
    }
    .pay-pal-btn {
        margin: 32px auto 0;
    }
}

.post-editing-info {
  &-changes {
    margin-top: 50px;
    font-size: 13px;
    overflow: auto;
    max-height: 500px;
    &__title {
      font-weight: bold;
    }
    &__item {
      margin-top: 30px;
      border-top: none;
      border-bottom: 1px solid #eceaea;
    }
    &__feedback {
      // margin-bottom: 50px;
    }
    &__date {
      font-size: 10px;
    }

    &__text {
      overflow: hidden;
      position: relative;
      line-height: 1.2em;
      max-height: 350px;
      text-align: justify;
      margin-right: -1em;
      padding-right: 1em;
    }

    &__text:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &__text:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: white;
    }
  }
}
.post-swiper{
  padding: 0;
}
.modal__cont {
  width: 100%;
  max-width: 808px;
  height: 100%;
}

