.hero {
    height: calc(100vh - 75px);
    max-height: 905px;
    min-height: 650px;
    position: relative;
    margin-bottom: 160px;
    overflow: hidden;
    &-left {
        .hero__bg-full-hd {
            object-position: right;
        }
        .hero__bg-ultra-hd {
            object-position: right;
        }
    }
    &-right {
        .hero__bg-full-hd {
            object-position: left;
        }
        .hero__bg-ultra-hd {
            object-position: left;
        }
    }
    .wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 2;
    }
    &__main {
        max-width: 946px;
        width: 100%;
        margin-top: auto;
    }
    &__bottom {
        display: flex;
        align-items: center;
        height: 80px;
        margin-top: auto;
    }
    h1 {
        margin: 8vh 0 9.3vh;
        color: var(--secondary-text-color);
    }
    &__bg-full-hd {
        position: absolute;
        right: 0;
        top: 0;
        width: 74.1%;
        height: 100%;
        object-fit: cover;
        border-radius: 0 0 0 10px;
    }
    &__bg-ultra-hd {
        position: absolute;
        top: 0;
        left: 0;
        width: 1833px;
        height: 100%;
        object-fit: cover;
        border-radius: 0 0 10px 10px;
        z-index: -2;
        margin-left: 322px;
    }
    &__square-img {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 5px);
        max-height: 880px;
        width: 1090px;
        margin-top: -75px;
        z-index: -1;
        object-fit: cover;
        object-position: top right;
        border-radius: 0 0 10px 10px;
        transform: translateX(calc(-100% + 468px));
    }
    &-navigation {
        display: flex;
        margin-left: 224px;
        &__item {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0 7px;
            cursor: pointer;
        }
    }
    .soc-seti {
        margin-left: 50px;
    }
}
.selector {
    display: flex;
    &__name {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: transparent;
        cursor: pointer;
    }
    &__line {
        width: 200px;
        height: 2px;
        align-self: flex-end;
        margin: 0 12px 4px;
    }
}
h1 {
    font-family: 'KronaOne', sans-serif;
    font-size: 64px;
    line-height: 120%;
}
.soc-seti {
    display: flex;
    &__link {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        position: relative;
        &:not(:last-child) {
            margin-right: 26px;
        }
    }
}
.video-box {
    overflow: hidden;
    &__bg {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1800px;
        object-fit: cover;
        border-radius: 10px;
        height: calc(100% - 260px);
    }
    &__block {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 50px;
    }
    &__group-text {
        display: flex;
        flex-direction: column;
        padding-left: 25%;
        position: relative;
        color: var(--secondary-text-color);
        z-index: 1;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 14%;
            height: 2px;
            background-color: currentColor;
            margin: 36px 0 0 6.2%;
        }
    }
    &__group-app {
        flex-direction: row;
        align-items: center;
        .video-box__title {
            margin-right: auto;
            align-self: flex-start;
        }
    }
    &__title {
        font-family: 'KronaOne', sans-serif;
        font-size: 36px;
        line-height: 1.4;
        max-width: 894px;
    }
    &__text {
        margin-bottom: 50px;
        max-width: 894px;
    }
    .video-box__group-app .btn {
        margin: 0 0 0 20px;
    }
}
.video {
    position: relative;
    height: 44vw;
    max-height: 650px;
    margin-bottom: 50px;
    box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
    z-index: 1;
    video {
        object-fit: cover;
    }
}
.come-attivare {
    &__block {
        display: flex;
        justify-content: space-between;
    }
    &__box {
        max-width: 450px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 90px;
        &:nth-child(1) .come-attivare__text:before {
            content: "1";
        }
        &:nth-child(2) {
            margin: 0 32px;
            .come-attivare__text:before {
                content: "2";
            }
        }
        &:nth-child(3) .come-attivare__text:before {
            content: "3";
        }
    }
    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: linear-gradient(270deg, #6A85F8 0%, #846ACF 100%);
        img {
            width: 56px;
            height: 56px;
            object-fit: contain;
        }
    }
    &__title {
        font-family: 'KronaOne', sans-serif;
        color: var(--come-attivare-title-color);
        line-height: 1.4;
        min-height: 56px;
        margin: 26px 0 18px;
    }
    &__text {
        line-height: 1.4;
        padding-bottom: 26px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100px;
            height: 2px;
            background-color: var(--come-attivare-color-line);
        }
        &:before {
            position: absolute;
            right: 100%;
            top: 0;
            width: 74px;
            margin-right: 16px;
            font-family: 'KronaOne', sans-serif;
            font-size: 72px;
            line-height: 1;
            color: var(--come-attivare-title-color);
        }
    }
    .btn {
        margin: 50px auto 0;
    }
}
.nostri-clienti {
    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 40px
    }
    &__link {
        max-width: 280px;
        width: 100%;
        display: flex;
        margin: 0 auto;
        img {
            object-fit: contain;
        }
    }
}
.nostra-community {
    margin-bottom: 140px;
    .title-krona {
        margin-bottom: 40px;
    }
}
.social-media {
    position: relative;
    overflow: hidden;
    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        min-height: 385px;
    }
    &__colum {
        display: flex;
        flex-direction: column;
        z-index: 1;
        &:not(:last-child) {
            margin-right: 18px;
        }
        &.box-one {
            min-width: 170px;
        }
        &.box-two {
            min-width: 254px;
        }
        &.box-three {
            min-width: 242px;
        }
    }
    &__title {
        font-family: 'KronaOne', sans-serif;
        font-size: 32px;
        width: 390px;
        padding-right: 20px;
        margin: 50px 0;
        color: var(--secondary-text-color);
    }
    &__icon {
        width: 76px;
        height: 76px;
        margin-bottom: 26px;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        height: 100%;
        width: 1800px;
        object-fit: cover;
        box-shadow: 0px 15px 25px -6px rgba(27,0,46, 0.5);
        pointer-events: none;
    }
    &__line {
        width: 50px;
        height: 3px;
        margin-bottom: 6px;
    }
    &__number {
        font-family: 'KronaOne', sans-serif;
        font-size: 30px;
        color: var(--secondary-text-color);
        margin-bottom: 8px;
        b {
            font-weight: 400;
        }
    }
    &__name {
        font-size: 14px;
        text-transform: uppercase;
        color: #7775FF;
        line-height: 1;
    }
    &__group {
        display: flex;
        width: 100%;
        max-width: 820px;
        flex-wrap: wrap;
    }
    &__text {
        font-size: 20px;
        color: var(--secondary-text-color);
        margin-top: -30px;
        margin-bottom: 50px;
    }
}
.swiper-container {
  width: 100%;
  height: auto;
  padding: 55px 0 80px;
}
.slider-community, .swiper-community {
    max-width: 1332px;
    width: 100%;
    padding: 0 166px;
    margin: 0 auto;
    position: relative;
    .swiper-slide-active {
        .slider-community__link {
            transform: scale(1.2);
        }
    }
    &__box__item{
      // display: flex;
      flex-wrap: wrap;
    }
    &__link {
        display: block;
        box-shadow: 0px 10px 25px rgba(27,0,46,0.25);
        border-radius: 15px;
        transition: .25s linear;
        overflow: hidden;
        img {
            object-fit: cover;
            height: auto;
        }
        video{
          width: 100%;
        }
    }
    .slick-track {
        padding: 55px 0 80px;
    }
    & .slick-prev, & .slick-next {
        &:active {
            color: var(--btn-primary-4-hover);
        }
    }
}
.costi-servizi {
    &__cont {
        display: flex;
        justify-content: space-between;
    }
    &__block {
        width: 100%;
        max-width: 440px;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        padding: 50px 46px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
        position: relative;
        z-index: 2;
        color: var(--secondary-text-color);
        &:nth-child(2) {
            margin: 0 30px;
        }
    }
    .btn {
        &.primary-btn-filled {
            max-width: 100%;
            margin-top: 26px;
        }
        &.tertiary-btn {
            max-width: 258px;
            width: 100%;
            margin: 48px auto 0;
        }
    }
    &__title {
        font-family: 'KronaOne', sans-serif;
        font-size: 30px;
        padding: 0 0 16px 40px;
        line-height: 1.2;
        margin-bottom: 40px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 80px;
            height: 2px;
            background: currentColor;
        }
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
    }
    &__item {
        font-size: 14px;
        text-transform: uppercase;
        padding-left: 34px;
        position: relative;
        cursor: default;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 22px;
            height: 22px;
        }
        &:not(:last-child) {
            margin-bottom: 22px;
        }
        &:not(.star):after {
            background: url("../../img/icons/sucsess-icons.svg") center/contain no-repeat;
        }
        &.star:after {
            background: url("../../img/icons/star-circle-icon.svg") center/contain no-repeat;
        }
    }
}
