.insights {
    &-bar-top {
        display: flex;
        flex-wrap: wrap;
        padding: 0 50px;
        margin-bottom: 20px;
        &__group {
            display: flex;
            align-items: center;
            &:first-child {
                padding-right: 20px;
                margin-right: auto;
                .insights-bar-top__svg {
                    margin-right: 4px;
                }
            }
            &:nth-child(2) {
                .insights-bar-top__svg {
                    margin-left: 4px;
                }
            }
        }
        &__svg {
            fill: currentColor;
            width: 20px;
        }
        &__text {
            font-size: 16px;
        }
    }
    &-bar {
        display: flex;
        position: relative;
        padding: 34px 50px;
        color: var(--secondary-text-color);
        justify-content: space-between;
        margin-bottom: 50px;
        &__colum {
            display: flex;
            flex-direction: column;
            z-index: 2;
            &:nth-child(1) {
                align-items: flex-start;
            }
            &:nth-child(2) {
                align-items: center;
            }
            &:nth-child(3) {
                align-items: flex-end;
            }
        }
        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        &__title {
            font-family: 'KronaOne', sans-serif;
            font-size: 24px;
            line-height: 1.3;
            margin-bottom: 10px;
            b {
                font-size: 18px;
            }
        }
        &__number {
            font-family: 'KronaOne', sans-serif;
            font-size: 36px;
            margin-top: auto;
            line-height: 1;
        }
        &__line {
            width: 100px;
            height: 1px;
            margin-top: 16px;
        }
    }
}
.community-insights{
  .audience-fullwidth{
    margin-top: 70px;
  }
}
.audience {
  margin-bottom: 120px;
  .progress-spinner{
    margin-bottom: 150px;
  }
  .wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  &__colum {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    justify-content: space-between;
    &:nth-child(1) {
      width: calc(100% - 710px);
      max-width: 616px;
      margin-right: 20px;
    }
    &:nth-child(2) {
      width: 690px;
    }
  }
  &-circle {
    position: relative;
    width: 100%;
    max-width: 314px;
    &__arrow {
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      transform: rotate(43deg);
      transition: .1s linear;
    }
  }
  &-fullwidth{
    width: 100%;
    &__description{
      text-align: center;
      color: var(--black);
      font-size: 16px;
      line-height: 6;
      margin-bottom: 40px;
    }
    &-collum{
      display: flex;
      justify-content: space-between;
      position: relative;
      &__left, &__right{
        :after{
          content: ' ';
          width: 150px;
          height: 1px;
          background-color: var(--violet);
          position: absolute;
          bottom: 0;
        }
      }
      &__right{
        text-align: right;
        :after{
          right: 0;
        }
      }
      &__left{
        text-align: left;

        :after{
          left: 0;
        }
      }

      &__title{
        color: var(--violet);
        font-family: 'KronaOne', sans-serif;
        font-size: 24px;
      }

      &__description{
        color: var(--violet-dark);
        font-family: 'KronaOne', sans-serif;
        font-size: 18px;
      }

      &__number{
        color: var(--green);
        font-size: 36px;
        font-family: 'KronaOne', sans-serif;
        line-height: 58px;
      }
    }
  }
}
.insights-h3 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
}
