@media screen and (min-width: 1921px) {
  .hero__bg-full-hd {
    display: none;
  }
}

@media screen and (max-width: 1920px) {
  .hero__bg-ultra-hd {
    display: none;
  }
}

@media screen and (max-width: 1504px) {
  .notebook-block-left {
    .notebook-block__img {
      margin-left: -3vw;
    }
  }
  .notebook-block-right {
    .notebook-block__img {
      margin-right: -3vw;
    }
  }

  .hero__bg-full-hd {
    width: calc(100% - 290px);
  }
  .nostri-clienti__wrap {
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 1440px) {
  .header-left .header__block{
    width: calc(100% - 240px)
  }
}

@media screen and (min-width: 1281px) and (max-width: 1504px) {
  .hero h1 {
    font-size: 56px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1504px) {
  .social-media-group__img {
    width: 22.8vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1350px) {
  .come-attivare__box {
    padding-left: 64px;
  }
  .come-attivare__text:before {
    width: 56px;
  }
}

@media screen and (min-width: 1281px) {
  .menu-btn, .header__block .soc-seti {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .header {
    &-left, &-right {
      .header__block {
        width: 300px;
      }
      .menu {
        width: 100%;
        padding: 0;
      }
    }
    &__block {
      position: fixed;
      top: 0;
      right: 0;
      flex-direction: column;
      background-color: #fff;
      height: 100vh;
      padding: 95px 30px 40px;
      align-items: flex-end;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 10;
      transition: .35s ease;
      transform: translateX(100%);
      &.active {
        transform: translateX(0);
      }
    }
    .btn-text {
      margin: auto 0;
      min-height: 26px;
      height: auto;
    }
    .btn {
      min-height: 50px;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .soc-seti {
      margin-top: 20px;
    }
  }
  .menu {
    flex-direction: column;
    align-items: flex-end;
    margin-top: auto;
    &__item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 12px;
    }
    &__link {
      font-size: 24px;
    }
  }
  .home-social-media .social-media {
    &__block {
      flex-wrap: wrap;
      padding-bottom: 50px;
    }
    &__colum {
      &:nth-child(2) {
        width: 100%;
        margin-right: 0;
      }
    }
    &__text {
      margin-right: 0;
    }
  }
  .slick-next:before, .slick-prev:after {
    width: 100px;
  }
  .come-attivare {
    &__text:before {
      display: none;
    }
    &__box {
      padding-left: 0;
      &:nth-child(1) .come-attivare__circle:before {
        content: "1";
      }
      &:nth-child(2) .come-attivare__circle:before {
        content: "2";
      }
      &:nth-child(3) .come-attivare__circle:before {
        content: "3";
      }
    }
    &__circle {
      position: relative;
      margin-left: 85px;
      &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 74px;
        font-family: 'KronaOne', sans-serif;
        font-size: 72px;
        line-height: 1;
        color: var(--text-blue);
        margin-left: -85px;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .simple-as__list {
    padding-left: 50px;
  }
  .simple-as__item:before {
    margin: 10px 15px 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .brand-text__img {
    margin-left: -110px;
  }
  .contact__colum:nth-child(1) {
    min-width: 540px;
  }
  .video-box__group-app {
    .app-link__wrap {
      flex-direction: column;
      .app-link:first-child {
        margin-bottom: 20px;
      }
    }
  }
  .costi-servizi__block {
    padding: 32px 24px;
  }
  .costi-servizi__title {
    font-size: 28px;
    padding: 0 0 16px 0px;
  }
  .footer__colum:nth-child(3) {
    width: 260px;
    min-width: 260px;
  }
  .nostri-clienti__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1180px) {
  .amplification-stat__box {
    flex-direction: column;
    width: 274px;
    margin: -18px 0 -30px 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1504px) {
  .video-box__bg {
    height: calc(100% - 17.3vw);
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .amplification-stat {
    padding: 100px 0;
  }
  .slider-community, .swiper-community {
    max-width: 1288px;
    padding: 0 146px;
  }
  .hero h1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 1024px) {
  .qualche-block {
    align-items: center;
    &__img {
      min-width: 320px;
    }
    &.qualche-block-left {
      flex-direction: column;
    }
    &.qualche-block-right {
      flex-direction: column-reverse;
    }
  }
  .qualche-block-left .qualche-block__colum {
    margin-left: 0;
  }
  .qualche-block-right .qualche-block__colum {
    margin-right: 0;
  }
  .perche-sponsorizzare {
    .wrapper-small {
      position: relative;
      justify-content: center;
    }
    &__colum {
      padding-right: 0;
      max-width: 896px;
    }
    &__img {
      position: absolute;
      bottom: 0;
      max-width: 100%;
      width: auto;
    }
  }
  .garantiamo-risultati {
    .wrapper-small {
      justify-content: center;
    }
    &.notebook-block-left {
      .notebook-block__colum {
        padding-left: 0;
      }
    }
  }
  .social-media__title {
    max-width: 390px;
    width: 100%;
    padding-right: 0;
  }
  .scegli-quali {
    .notebook-block__block:first-child .notebook-block__text {
      max-width: 100%;
    }
    .notebook-block__group .notebook-block__text {
      max-width: 420px;
    }
  }
  .notebook-block-left {
    .notebook-block__colum {
      padding-left: 0;
    }
    .notebook-block__bg {
      margin-left: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .notebook-block-right {
    .notebook-block__colum {
      padding-right: 0;
    }
    .notebook-block__bg {
      margin-left: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .notebook-block {
    .wrapper {
      flex-direction: column;
      position: relative;
    }
    &__img {
      position: absolute;
      bottom: 0;
      max-width: unset;
      width: auto;
    }
    &__colum {
      max-width: 896px;
    }
  }
  .social-media-group {
    flex-direction: column-reverse;
    align-items: center;
  }
  .social-media-brand {
    .social-media__group {
      padding-bottom: 106px;
    }
    .social-media__block {
      flex-direction: column;
    }
    .social-media__colum.box-caption {
      width: 100%;
    }
    .social-media__group {
      max-width: calc(100% + 18px);
      margin-left: -18px;
      width: calc(100% + 18px);
      .social-media__colum {
        width: calc((100% - 54px) / 3);
      }
    }
  }
  .brand-text__colum:nth-child(1) {
    margin-bottom: -46px;
  }
  .brand-text__img {
    width: 100%;
    max-width: 544px;
  }
  .brand-text__cont {
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
  }
  .contact {
    &__cont {
      flex-direction: column;
      align-items: center;
    }
    &__colum {
      &:nth-child(1) {
        margin-right: 0;
        margin-bottom: 30px;
      }
      &:nth-child(2) {
        max-width: 740px;
      }
    }
    &__group {
      margin-top: 0;
    }
    .checked {
      margin-top: 0;
    }
  }
  .amplification-stat {
    &__cont {
      flex-direction: column;
    }
    &__group {
      max-width: 600px;
      width: 100%;
      min-width: unset;
      padding-right: 0;
      align-self: flex-start;
    }
  }
  .influencer-slider {
    flex-direction: column;
    .simple-as {
      &__colum {
        .swiper-container {
          width: 334px;
        }
        &:nth-child(2) {
          width: 100%;
          margin: 0 0 50px;
        }
      }
    }
  }
  .simple-as {
    &__colum {
      align-items: center;
      &:first-child {
        align-self: flex-start;
        align-items: flex-start;
      }
    }
  }
  .influencer-text {
    &__cont {
      flex-direction: column-reverse;
    }
    &__group {
      max-width: 100%;
      padding-bottom: 0;
    }
    &__img {
      margin-bottom: -50px;
      align-self: center;
    }
  }
  .costi-servizi {
    &__cont {
      flex-direction: column;
      align-items: center;
    }
    &__block {
      align-self: unset;
    }
    .btn.primary-btn-filled {
      margin-top: 48px;
    }
  }
  .nostri-clienti__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }
  .come-attivare {
    &__box, .btn {
      max-width: 360px;
    }
    &__block {
      flex-direction: column;
      align-items: center;
    }
  }
  .swiper-slide.swiper-slide-active .slider-community__link {
    transform: unset;
  }
  footer {
    padding-bottom: 30px;
  }
  .footer {
    &__colum:nth-child(2) {
      margin: 0;
    }
    &__row {
      &:nth-child(1) {
        flex-wrap: wrap;
        .footer__colum {
          &:nth-child(1) {
            width: 100%;
          }
        }
      }
      &:nth-child(2) {
        flex-direction: column;
        .footer__colum {
          &:nth-child(1) {
            order: 2;
          }
          &:nth-child(2) {
            max-width: 100%;
            order: 3;
          }
          &:nth-child(3) {
            order: 1;
          }
        }
      }
    }
    .select-box {
      position: absolute;
      top: 0;
      right: 0;
      margin: 16px 30px 0 0;
    }
  }
  .audience__colum{
    &:nth-child(1), &:nth-child(2) {
      width: 100%;
    }
  }
  .information__campaign-wallet{
    bottom: 15px;
    right: -2px;
  }
  .information{
    width: 8px;
    height: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .perche-sponsorizzare {
    &__block {
      &:nth-child(1) {
        padding-bottom: 32vw;
      }
      &:nth-child(2) {
        padding: calc(40px + 16vw) 0 40px;
      }
    }
    &__img {
      margin: 0 0 278px;
      height: 48vw;
    }
  }
  .garantiamo-risultati {
    .notebook-block__block {
      &:nth-child(1) {
        padding-bottom: 28vw;
      }
      &:nth-child(2) {
        padding: calc(40px + 28vw) 0 50px;
      }
    }
    .notebook-block__img {
      height: 56vw;
      margin-bottom: 216px;
    }
  }
  .notebook-block {
    &__img {
      height: 36vw;
      margin-bottom: 268px;
    }
    &__block {
      &:nth-child(1) {
        padding-bottom: 22vw;
      }
      &:nth-child(2) {
        padding: calc(40px + 14vw) 0 50px;
      }
    }
  }
  .social-media-group__img {
    margin: -156px 0 0 0;
  }
  .amplification-stat {
    &__cont {
      padding: 50px 0;
    }
    &__img {
      margin: 50px 0;
    }
  }
  .video-box .video-box__group-app .btn {
    margin: 50px 0 0 0;
  }
  .video-box__group-app {
    flex-direction: unset;
    flex-wrap: wrap;
    .video-box__title {
      width: 100%;
    }
    .app-link {
      margin: 20px 20px 0 0;
    }
  }
  .accordion__main {
    padding: 10px 112px 26px 40px;
  }
  .costi-servizi {
    &__block {
      &:nth-child(2) {
        margin: 50px 0;
      }
    }
  }
  .come-attivare__box {
    &:nth-child(1), &:nth-child(2) {
      margin: 0 0 50px;
    }
  }
  .video-box__group-text {
    padding-left: calc(18% + 16px);
    &:after {
      width: 18%;
      max-width: 165px;
      margin: 36px 0 0 0;
    }
  }
  .footer {
    &__text.email {
      margin: 50px 0 16px 0;
    }
    &__row {
      &:nth-child(1) {
        margin-bottom: 50px;
        .footer__colum {
          &:nth-child(1) {
            margin-bottom: 40px;
          }
          &:nth-child(2) {
            max-width: calc(100% - 188px);
          }
          &:nth-child(3) {
            position: relative;
            min-width: 158px;
            width: 158px;
          }
        }
      }
    }
    .btn-text {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: -76px;
    }
  }
  .hero {
    .soc-seti {
      margin: 0;
    }
    &__bg-full-hd {
      width: calc(100% - 220px);
    }
    &-navigation {
      margin-left: 276px;
    }
  }
  .slider-community, .swiper-community {
    max-width: 716px;
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .amplification-stat {
    &__box {
      width: 100%;
      margin: 0;
      justify-content: space-evenly;
    }
  }
}

@media screen and (max-width: 767px) {
  .saas-text h2 {
    margin-bottom: 40px;
  }
  .perche-sponsorizzare {
    &__text {
      margin-bottom: 24px;
    }
  }
  .garantiamo-risultati {
    .notebook-block__block {
      &:nth-child(1) {
        padding-bottom: 172px;
      }
      &:nth-child(2) {
        padding: 196px 0 24px;
      }
    }
    .notebook-block__img {
      height: 344px;
    }
    .notebook-block__group .notebook-block__text {
      padding: 0;
    }
  }
  .notebook-block {
    &__img {
      max-width: 100%;
      height: 246px;
    }
    &__block {
      &:nth-child(1) {
        padding-bottom: 150px;
      }
    }
    .mb-40 {
      margin-bottom: 24px;
    }
    &__title {
      font-size: 32px;
      margin-bottom: 18px;
    }
  }
  .notebook-block-left .notebook-block__img {
    margin-left: 0;
  }
  .notebook-block-right .notebook-block__img {
    margin-right: 0;
  }
  .social-media-group__img {
    max-width: 430px;
    width: calc(100% + 30px);
    margin: -155px 0 0 0;
  }
  .brand-text__cont {
    padding: 0 15px;
  }
  .form__checked {
    margin: 20px 0 40px;
  }
  .modal-brand-login .btn {
    margin: 10px auto 0;
  }
  .form__group--three {
    width: calc((100% - 40px) / 3);
  }
  .contact {
    &__cont {
      padding: 40px 0;
    }
    &__icon {
      margin-right: 34px;
    }
    &__text {
      font-size: 24px;
    }
    a.contact__text {
      padding-top: 6px;
    }
  }
  .amplification-stat {
    padding: 80px 0;
  }
  .amplification-stat__title {
    font-size: 32px;
  }
  .amplification-stat {
    &__cont {
      padding: 40px 0;
    }
    &__img {
      margin: 40px 0;
    }
    &__box {

    }
  }
  .social-media {
    &__title {
      margin: 40px 0;
    }
    &__text {
      margin-top: -20px;
      margin-bottom: 40px;
    }
  }
  .home-social-media .social-media__block {
    padding-bottom: 40px;
  }
  .influencer-slider {
    .simple-as__img {
      max-width: 344px;
      width: 100%;
    }
    .simple-as__colum:nth-child(2) {
      margin: 0 0 40px;
    }
  }
  .simple-as {
    &__cont {
      padding: 40px 0;
    }
    &__h3 {
      font-size: 32px;
      margin-bottom: 30px;
    }
    &__list {
      margin-bottom: 40px;
    }
  }
  .video-box .video-box__group-app .btn {
    margin: 40px 0 0 0;
  }
  .video-box__group-app {
    flex-direction: unset;
    flex-wrap: wrap;
    .app-link {
      margin: 20px 20px 0 0;
      &__wrap {
        margin-top: 20px;
        flex-wrap: wrap;
      }
    }
  }
  .pb-160 {
    padding-bottom: 100px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .mb-100 {
    margin-bottom: 80px;
  }
  .mt-100 {
    margin-top: 80px;
  }
  .mb-60 {
    margin-bottom: 50px;
  }
  .mt-160 {
    margin-top: 80px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  h3 {
    font-size: 18px;
  }
  .accordion {
    margin-top: -40px;
    &__main {
      padding: 10px 78px 26px 22px;
    }
  }
  .slider-community, .swiper-community {
    padding: 0;
    max-width: 424px;
    .slick-next:before, .slick-prev:after {
      display: none;
    }
    .slick-prev {
      left: 36px;
    }
    .slick-next {
      right: 36px;
    }
  }
  .costi-servizi {
    &__block {
      &:nth-child(2) {
        margin: 40px 0;
      }
    }
  }
  .mb-160, .nostra-community, .nostra-community {
    margin-bottom: 100px;
  }
  h2 {
    font-size: 48px;
    margin-bottom: 28px;
    word-wrap: break-word;
  }
  .title-krona {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .come-attivare {
    &__box {
      &:nth-child(1), &:nth-child(2) {
        margin: 0 0 40px;
      }
    }
    .btn {
      margin: 40px auto 0;
    }
  }
  .video {
    margin-bottom: 40px;
    &__bg img {
      width: 74px;
      height: 74px;
    }
  }
  .video-box {
    &__group-text {
      padding-left: 46px;
      &:after {
        width: 30px;
        margin: 36px 0 0 0;
      }
    }
    &__bg {
      height: calc(100% - 9vw);
    }
    &__block {
      padding-bottom: 40px;
    }
    &__text {
      margin-bottom: 40px;
    }
  }
  .header {
    &:after {
      left: -15px;
      width: calc(100% + 30px);
      transform: unset;
    }
    &-left:after {
      background: var(--primary-gradient-header);
    }
    &-right:after {
      background: var(--secondary-gradient-header);
    }
  }
  .logo {
    &__text {
      font-size: 14px;
    }
    &__img {
      width: 52px;
      height: 44px;
      margin-right: 6px;
    }
  }
  .footer {
    &__text.email {
      margin: 18px 0 8px 0;
    }
    &__colum {
      &:nth-child(3) {
        width: 100%;
        min-width: 100%;
      }
    }
    &__row {
      &:nth-child(1) {
        margin-bottom: 30px;
        .footer__colum {
          &:nth-child(1) {
            margin-bottom: 24px;
          }
          &:nth-child(2) {
            max-width: 100%;
          }
          &:nth-child(3) {
            flex-direction: column;
            width: 100%;
            .soc-seti {
              margin-bottom: 30px;
            }
          }
        }
      }
      &:nth-child(2) {
        text-align: center;
        align-items: center;
      }
    }
    &__text {
      width: 100%;
    }
    &__list {
      width: 100%;
      justify-content: center;
      margin-bottom: 30px;
    }
    .select-box {
      margin: 10px 15px 0 0;
    }
    &__item:not(:last-child) {
      margin-right: 8px;
    }
    &__link {
      font-size: 14px;
    }
    .btn {
      margin: 0 auto;
    }
  }
  .selector__line {
    width: 130px;
    margin: 0 10px 4px;
  }
  .wrapper, .wrapper-small {
    padding: 0 15px;
  }
  .hero {
    height: auto;
    margin-bottom: 40px;
    h1 {
      font-size: 36px;
      margin: 60px 0 80px;
      word-wrap: break-word;
    }
    &__square-img {
      display: none;
    }
    &__bg-full-hd {
      width: 100%;
      border-radius: 0;
      height: calc(100% - 50px);
    }
    &__bottom {
      flex-direction: column-reverse;
      height: auto;
    }
    &__main {
      margin: 90px 0 60px;
    }
    .soc-seti {
      margin: 30px 0 0 0;
      padding-top: 30px;
    }
    &-navigation {
      margin: 0;
    }
  }
  .social-media {
    .social-media__colum {
      &.box-one, &.box-two, &.box-three {
        min-width: 100%;
        margin-right: 0;
      }
      &.box-one, &.box-two {
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .perche-sponsorizzare {
    &__block {
      &:nth-child(1) {
        padding-bottom: 248px;
      }
      &:nth-child(2) {
        min-height: 510px;
        padding: 144px 0 24px;
      }
    }
    &__img {
      margin: 0 0 390px;
      height: 368px;
    }
  }
  .garantiamo-risultati {
    .notebook-block__block {
      &:nth-child(2) {
        min-height: 380px
      }
    }
    .notebook-block__img {
      margin-bottom: 208px;
    }
  }
  .notebook-block {
    &__img {
      margin-bottom: 232px;
    }
    &__block {
      &:nth-child(2) {
        min-height: 328px;
        padding: 110px 0 24px;
      }
    }
  }
  .form__group--two {
    width: calc((100% - 20px) / 2);
  }
  .modal-small {
    padding: 0 15px 30px;
  }
  .modal-close {
    margin: -63px 15px 0 0;
  }
  .modal__cont {
    padding: 60px 30px 50px;
  }
}

@media screen and (max-width: 575px) {
  .qualche-block__quotes {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin: 0 16px 0 0;
  }
  .perche-sponsorizzare-list__item {
    text-transform: unset;
  }
  .perche-sponsorizzare {
    &__block {
      &:nth-child(1) {
        padding-bottom: 188px;
      }
      &:nth-child(2) {
        min-height: 560px;
        padding: 124px 0 24px;
      }
    }
    &__img {
      margin: 0 0 460px;
      height: 288px;
    }
  }
  .garantiamo-risultati {
    .notebook-block__block {
      &:nth-child(2) {
        min-height: 508px;
      }
    }
    .notebook-block__img {
      margin-bottom: 338px;
    }
  }
  .notebook-block {
    &__img {
      margin-bottom: 387px;
    }
    &__block {
      &:nth-child(2) {
        min-height: 480px;
        padding: 110px 0 16px;
      }
    }
    &__block {
      &:nth-child(2):not(.colum) {
        flex-direction: column;
      }
      .honor {
        margin: auto;
      }
    }
    &__group {
      flex-direction: column;
      &:not(.colum) {
        min-height: 272px;
      }
      .social-media__colum {
        margin: auto;
      }
      .notebook-block__text {
        padding: 0 0 16px 0;
      }
    }
  }
  .modal {
    margin: 0;
  }
  .modal-small {
    padding: 0;
  }
  .modal-brand-login .modal__cont {
    padding-bottom: 136px;
  }
  .modal__cont {
    border-radius: 0;
    padding: 20px 15px 50px;
    min-height: 100vh;
  }
  .modal-close {
    margin: 15px 15px 0 0;
  }
  .modal__title {
    font-size: 24px;
    margin-bottom: 50px;
    margin-top: 76px;
  }
  .form__group--two:nth-child(1) .btn-text {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 60px;
  }

  .form__group--two, .form__group--three {
    width: 100%;
  }
  .contact {
    &__colum {
      flex-direction: column;
    }
    &__group {
      flex-direction: column;
    }
    .btn {
      max-width: 258px;
      margin: 40px 0 0;
    }
  }
  .amplification-stat__box {
    flex-direction: column;
    width: 274px;
    margin: -18px 0 -30px -6px;
  }
}

@media screen and (max-width: 480px) {
  .brand-text__img {
    width: calc(100% + 94px);
    margin-right: -15px;
  }
  .nostra-community {
    overflow: hidden;
  }
  .slider-community, .swiper-community {
    max-width: unset;
    width: calc(100% + 50px);
    margin-left: -25px;
    .slick-slide {
      margin: 0 10px;
    }
    .slick-prev {
      left: 15px;
    }
    .slick-next {
      right: 15px;
    }
  }
  .costi-servizi {
    &__block {
      width: calc(100% + 30px);
      max-width: unset;
      border-radius: 0;
      padding: 50px 15px;
    }
  }
}

@media screen and (max-width: 400px) {
  .amplification-stat__img {
    max-width: unset;
    width: calc(100% + 30px);
  }
  .btn.big.green {
    max-width: 100%;
  }
  .video-box {
    .btn.big.green {
      max-width: calc(100% + 46px);
      width: calc(100% + 46px);
      margin-left: -46px;
    }
  }
  .selector {
    width: 100%;
    &__name {
      white-space: nowrap;
    }
    &__line {
      width: 100%;
    }
  }
}


// height

@media screen and (min-width: 1024px) and (min-height: 981px)  {
  .hero h1 {
    margin: 76px 0 90px;
  }
}
