@font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/OpenSans-Regular.eot');
    src: url('./fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/OpenSans-Regular.woff') format('woff'),
    url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/OpenSans-SemiBold.eot');
    src: url('./fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/OpenSans-SemiBold.woff') format('woff'),
    url('./fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/OpenSans-Bold.eot');
    src: url('./fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/OpenSans-Bold.woff') format('woff'),
    url('./fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'KronaOne';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('./fonts/KronaOne-Regular.eot');
    src: url('./fonts/KronaOne-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/KronaOne-Regular.woff') format('woff'),
    url('./fonts/KronaOne-Regular.ttf') format('truetype');
}
