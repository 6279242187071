.campaign {
    overflow: hidden;
    .wrapper-big {
        position: relative;
    }
    h2 {
        margin-bottom: 16px;
    }
    &__cont:not(:last-child) {
        margin-bottom: 50px;
    }
    &__top {
        display: flex;
        align-items: flex-end;
        color: var(--secondary-text-color);
        padding: 50px 0 100px;
        z-index: 2;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: calc(100% - 64px);
        object-fit: cover;
        border-radius: 10px;
        z-index: -1;
        margin-left: 32px;
        box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
    }
    &__top-line {
        width: 14%;
        height: 2px;
        background-color: currentColor;
        margin: 0 5% 12px 0;
    }
    &__main-title, &__number {
        font-family: 'KronaOne', sans-serif;
        font-size: 36px;
        line-height: 1.4;
    }
    &__number {
        margin-left: auto;
        padding-left: 20px;
        min-width: 100px;
        text-align: right;
    }
    &__middle {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px 60px;
        margin: -62px 0 50px;
        z-index: 2;
        position: relative;
    }
    &-box {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        &:hover {
            .campaign-box__hover {
                opacity: 1;
                pointer-events: auto;
            }
            .campaign-box__img {
                box-shadow: none;
            }
        }
        &__img {
            height: 16vw;
            max-height: 240px;
            border-radius: 10px;
            box-shadow: 0px 10px 25px -15px rgba(27, 0, 46, 0.5);
            position: relative;
            img {
                border-radius: 10px;
                object-fit: cover;
                display: block;
                background-color: #fff;
            }
        }
        &__hover, &__on_review {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            pointer-events: none;
            opacity: 0;
            * span, * b {
                cursor: pointer;
            }
        }
        &__on_review {
          opacity: 1;
          pointer-events: none;
          z-index: 5;
          cursor: auto;
        }
        &__group {
            max-width: 290px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        &__text {
            line-height: 1.2;
           align-items: center;
            //white-space: nowrap;
            b {
                font-weight: 600;
            }
        }
        &__line {
            width: 60px;
            min-width: 20px;
            height: 1px;
            background-color: currentColor;
            align-self: flex-end;
            margin: 0 6px 3px;
        }
        &__title {
            line-height: 22px;
            font-size: 18px;
            padding: 14px 0;
            color: var(--tertiary-text-color);
            text-transform: uppercase;
            font-family: 'KronaOne', sans-serif;
            cursor: pointer;
        }
    }
    &__bottom {
        display: flex;
        justify-content: center;
        max-width: 1258px;
        margin: 0 auto;
        .btn {
            max-width: 258px;
        }
    }
}
