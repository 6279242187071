.form {
    display: flex;
    &__box {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        color: var(--blue);
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    &__title {
        font-size: 14px;
        color: currentColor;
        line-height: 1;
        margin-bottom: 4px;
        text-transform: uppercase;
    }
    &__input {
        height: 56px;
        padding: 0 20px;
    }
    &__input, &__textarea {
        width: 100%;
        border-radius: 5px;
        border: 1px solid transparent;
        box-shadow: inset 0px 4px 13px -2px rgba(13, 11, 109, 0.1);
        background-color: #fff;
        font-size: 16px;
    }
    &__textarea {
        height: 150px;
        padding: 18px 20px;
        resize: none;
    }
    &__error {
        font-size: 12px;
        line-height: 1.4;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 2px;
        pointer-events: none;
        opacity: 0;
    }
    &__checked {
        display: flex;
        width: 100%;
        margin: 10px 0 50px;
    }
}
.checked {
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
        display: none;
        &:checked + .checked__square:after {
            opacity: 1;
        }
    }
    &__square {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 16px;
        min-width: 16px;
        height: 16px;
        border: 1px solid transparent;
        border-radius: 5px;
        margin-right: 6px;
        box-shadow: inset 0px 4px 13px -2px rgba(13, 11, 109, 0.1);
        cursor: pointer;
        &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 4px;
            pointer-events: none;
            opacity: 0;
        }
    }
    &__text {
        cursor: pointer;
        font-size: 12px;
        line-height: 1.4;
    }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
.switch {
    display: flex;
    width: 80px;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    padding: 0 13px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    * {
        cursor: pointer;
    }
    &__input {
        display: none;
        &:checked {
            ~ .switch__circle {
                    margin-left: 54px;
            }
            ~ .switch__text.off {
                opacity: 0;
            }
            ~ .switch__text.on {
                opacity: 1;
            }
        }
    }
    &__circle {
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background-color: currentColor;
        border-radius: 50%;
        margin-left: 6px;
        transition: .25s ease;
    }
    &__text {
        font-size: 16px;
        text-transform: uppercase;
        line-height: 1;
        &.on {
            opacity: 0;
        }
    }
    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        z-index: -1;
    }
    &__bg, &__text {
        transition: .1s linear;
    }
}
