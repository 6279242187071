.new-campaign {
    .wrapper-small {
        position: relative;
        z-index: 2;
    }
    .wrapper {
        position: relative;
    }
    h2 {
        text-align: center;
    }
}
.steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 38px 0;
    &__title {
        font-family: 'KronaOne', sans-serif;
        font-size: 24px;
        line-height: 1;
        background-color: transparent;
    }
    &__line {
        max-width: 100px;
        width: 100%;
        height: 1px;
        margin: 0 20px;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 64px);
        transform: translateX(-50%);
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
}
.new-campaign-form {
    max-width: 1504px;
    width: 100%;
    padding: 0 32px;
    margin: 0 auto 50px auto;
    &__bottom {
        display: flex;
        margin-bottom: 50px;
        .btn {
            width: 100%;
        }
        .back {
            width: calc(37.4% - 8px);
            max-width: 216px;
            margin-right: 16px;
            padding: 0 4px;
        }
        .next {
            width: calc(62.6% - 8px);
            max-width: 348px;
            margin-left: auto;
        }
    }
    &__wrapper {
        display: flex;
        max-width: 1258px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        justify-content: space-between;
    }
    &__colum {
        display: flex;
        flex-direction: column;
        width: 100%;
        &:first-child {
            max-width: 630px;
            margin-right: 32px;
        }
        &:nth-child(2) {
            max-width: 530px;
        }
    }
    .information {
        margin: 2px 0 0 auto;
    }
    .filter-soc-net__box {
        max-width: 340px;
    }
    &__btn-group {
        display: flex;
        justify-content: space-between;
        .btn {
            width: calc((100% - 16px) / 2);
            &__text {
                font-size: 14px;
            }
        }
    }
    &__slider {
        display: flex;
        align-items: center;
        min-height: 48px;
        margin-top: 20px;

      .ngx-slider {
        .ngx-slider-selection {
          background: var(--main-iol-hover) !important;
        }
        .ngx-slider-pointer {
          background: var(--main-iol-hover) !important;
        }
      }
    }
    &__main-title {
        display: flex;
        font-family: 'KronaOne', sans-serif;
        font-size: 36px;
        margin-bottom: 50px;
        line-height: 1;
        &:before {
            content: "";
            display: block;
            width: 176px;
            height: 2px;
            align-self: flex-end;
            background-color: currentColor;
            margin-right: 46px;
            margin-bottom: 4px;
        }
    }
    .checked {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    .form__box {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    .form__title {
        margin-bottom: 18px;
        &-group {
            display: flex;
        }
    }
    .form__input {
        height: 50px;
        color: currentColor;
    }
    .form__textarea {
        height: 140px;
    }
}
.tile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    padding: 10px;
    border-radius: 10px;
    &__box {
        display: flex;
        width: 100%;
        box-shadow: 0px 10px 25px -15px rgba(27, 0, 46, 0.5);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        &.small {
            height: 140px;
        }
        &.big {
            height: 350px;
        }
    }
}
.upload {
  justify-content: center;
    &__add {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 4;
    }
    &__icons {
        width: 68px;
        height: 68px;
    }
    &__text {
        font-size: 16px;
        line-height: 1;
        pointer-events: none;
        margin-top: 10px;
    }
    input {
        display: none;
    }
    &__box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        img{
          height: auto;
          width: auto;
        }
        .btn-circle {
            position: absolute;
            top: 0;
            right: 0;
            margin: 8px 8px 0 0;
            &__svg {
                width: 20px;
                height: 20px;
                min-width: 20px;
            }
        }
    }
    &__img {
        // object-fit: cover;
      width: auto;
      height: auto;
    }
}
.custum-input {
    position: relative;
    &.icon {
        .form__input {
            padding-left: 36px;
        }
    }
    &.button {
        .form__input {
            padding-right: 54px;
        }
    }
    &__icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        margin-left: 14px;
        margin-right: 8px;
        object-fit: contain;
        pointer-events: none;
    }
    &__icon-delete {
      width: 14px;
      height: 14px;
      object-fit: contain;
      cursor: pointer;
      margin-right: 5px;
    }
    .btn-circle {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 20px;
        &__svg {
            width: 26px;
            height: 26px;
            min-width: 26px;
            transform: rotate(45deg);
        }
    }
}
.interests {
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    cursor: pointer;
    &__text {
        font-size: 16px;
        text-transform: uppercase;
        padding-left: 7.144%;
        padding-right: 10px;
        width: 100%;
        line-height: 1.4;
        cursor: pointer;
    }
    &__svg {
        width: 70px;
        min-width: 70px;
        height: auto;
        align-self: flex-end;
    }
}
.new-campaign-review {
    margin-bottom: 80px;
    &__wrapper, .new-campaign-moodboard {
        max-width: 1258px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    &__colum {
        display: flex;
        flex-direction: column;
        width: 100%;
        &:nth-child(1) {
            max-width: 560px;
            margin-right: 32px;
        }
        &:nth-child(2) {
            max-width: 630px;
        }
    }
    &__box {
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    &__title {
        font-size: 16px;
        text-transform: uppercase;
        line-height: 22px;
        margin-bottom: 18px;
    }
    &__logo-box {
        display: flex;
        align-items: center;
    }
    &__logo-img {
        width: 86px;
        min-width: 86px;
        height: 86px;
        object-fit: contain;
    }
    &__logo-group {
        display: flex;
        flex-direction: column;
        padding-left: 8.3%;
    }
    &__logo-title {
        font-family: 'KronaOne', sans-serif;
        font-size: 16px;
        margin-bottom: 8px;
    }
    &__logo-subtitle {
        font-size: 16px;
        line-height: 1.4;
    }
    &__text {
        font-size: 16px;
        &:not(:last-child) {
            margin-bottom: 18px;
        }
    }
    &__succsess, &__error {
        display: flex;
        &:before {
            content: "";
            width: 14px;
            height: 14px;
            margin: 6px 14px 0 0;
        }
    }
    &__succsess:before {
        background: url("../../img/icons/success.svg") center/contain no-repeat;
    }
    &__error:before {
        background: url("../../img/icons/error.svg") center/contain no-repeat;
    }
}
.tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    &__icons {
        width: 14px;
        height: 14px;
        object-fit: contain;
        margin-top: 11px;
        margin-right: 10px;
    }
    &__text {
        font-size: 16px;
        line-height: 1;
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 16px;
        height: 36px;
        border-radius: 30px;
    }
}
.new-campaign-moodboard {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 18px;
    &__box {
        display: flex;
        height: 140px;
        box-shadow: 0px 10px 25px -15px rgba(27, 0, 46, 0.5);
        border-radius: 10px;
        overflow: hidden;
        img {
            object-fit: cover;
        }
    }
}
.new-campaign-submit {
    &__wrapper-colum {
        flex-direction: column;
    }
    &__colum {
        display: flex;
        flex-direction: column;
        max-width: 621px;
        width: 100%;
        &:first-child {
            margin-right: 16px;
        }
    }
}
.dont-dos {
  font-size: 12px;
  padding-left: 15px;
  margin-top: 15px;
  li {
    padding: 5px 0;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    &:hover {
      // background-color: var(--footer-logo-text-color);
    }
  }
}
