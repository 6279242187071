.contact {
    overflow: hidden;
    &__cont {
        display: flex;
        max-width: 1258px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 0;
        position: relative;
    }
    &__bg {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1800px;
        object-fit: cover;
        border-radius: 10px;
        height: 100%;
    }
    &__colum {
        display: flex;
        z-index: 2;
        &:nth-child(1) {
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 740px;
            width: 100%;
            margin-right: 40px;
        }
        &:nth-child(2) {
            flex-direction: column;
            max-width: 478px;
            width: 100%;
        }
    }
    &__group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        .btn {
            max-width: 258px;
            margin-left: 16px;
        }
    }
    .checked {
        align-self: flex-start;
        margin-top: 13px;
    }
    &__box {
        display: flex;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    &__text {
        font-family: 'KronaOne', sans-serif;
        color: currentColor;
        font-size: 32px;
        line-height: 1.2;
    }
    &__icon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 50px;
    }
    .form__textarea {
        height: 160px;
    }
}