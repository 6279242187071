header {
  display: flex;
  position: sticky;
  background-color: #fff;
  top: 0;
  height: 75px;
  z-index: 9;
  overflow: hidden;
}
.header {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  .btn-text {
    white-space: nowrap;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    width: calc(100% - 480px);
  }
  &__group {
    display: flex;
    margin-left: 20px;
  }
  .btn.small {
    min-width: 200px;
    border-radius: 30px;
  }
}
.logo {
  display: flex;
  align-items: center;
  &__img {
    width: 250px;
    object-fit: contain;
    //height: 54px;
    margin-right: 12px;
  }
  &__text {
    font-family: 'KronaOne', sans-serif;
    font-size: 16px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: var(--primary-logo-text-color)
  }
}
.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__item {
    display: flex;
    margin-right: 20px;
  }
  &__link {
    font-size: 16px;
    line-height: 1;
    padding: 4px;
    color: var(--tertiary-text-color);
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      transform-origin: 50% 50%;
      transform: scale(0);
      width: 100%;
      height: 1px;
      background-color: currentColor;
    }
  }
}
$bar-width: 46px;
$bar-height: 4px;
$bar-spacing: 13px;
.menu-btn {
  width: $bar-width;
  height: 30px;
  cursor: pointer;
  z-index: 11;
  margin-left: auto;
}
.menu-btn__hamburger {
  width: 30px;
  height: $bar-height;
  will-change: top, bottom, transform;
  margin-left: 16px;
}
.menu-btn__hamburger:after,
.menu-btn__hamburger:before {
  width: $bar-width;
  height: $bar-height;
  will-change: top, bottom, transform;
}
.menu-btn__hamburger {
  position: relative;
  transform: translateY($bar-spacing);
  transition: all 0ms 300ms;
  &.active {
    background: rgba(255, 255, 255, 0);
  }
}
.menu-btn__hamburger:before {
  content: "";
  position: absolute;
  left: -16px;
  bottom: $bar-spacing;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), background 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-btn__hamburger:after {
  content: "";
  position: absolute;
  left: -16px;
  top: $bar-spacing;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), background 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-btn__hamburger.active:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), background 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-btn__hamburger.active:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), background 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
